import type { FarmStore } from '../../../dataHandlers/FarmStore';
import { useAIStateContext } from '../context/AIStateContext';

export interface HistoricThreadMessage {
  role: string;
  content: Array<{
    type: string;
    text: {
      value: string;
      annotations: Array<{
        end_index: number;
        file_citation: { file_id: string };
        start_index: number;
        text: string;
        type: string;
      }>;
    };
  }>;
}

export type ToolStatus = {
  performingAction: boolean;
  activeTools: string[]; // Array of active tool IDs
};

export interface Thread {
  threadId: string;
  userId: string;
  createdAt: Date;
  chatType: 'general' | 'irrigation';
  summary?: string;
  siteId?: string;
}

export interface AIState {
  generalChat: boolean | null;
  selectedSiteId: string;
  selectedHistoricThreadId: string | null;
  historicThreadMessages: HistoricThreadMessage[] | null;
  loadingHistoricThread: boolean;
  chatKey: number;
  threads: Thread[];
  cropType: string | null;
  locationType: string | null;
  threadId: string | null;
  existsThreadInLast5Minutes: boolean;
  initialLoading: boolean;
  allThreadsLoading: boolean;
  period: '7days' | '30days' | null;
  aiFarmStore: FarmStore;
  toolStatus: ToolStatus;
}

export interface AIStateActions {
  setGeneralChat: (value: boolean | null) => void;
  setSelectedSiteId: (id: string) => void;
  setSelectedHistoricThreadId: (id: string | null) => void;
  setHistoricThreadMessages: (messages: HistoricThreadMessage[]) => void;
  setLoadingHistoricThread: (loading: boolean) => void;
  setChatKey: React.Dispatch<React.SetStateAction<number>>;
  setThreads: React.Dispatch<React.SetStateAction<Thread[]>>;
  setCropType: (type: string | null) => void;
  setLocationType: (type: string | null) => void;
  fetchAllThreads: () => Promise<void>;
  setThreadId: (id: string | null) => void;
  setPeriod: (period: '7days' | '30days' | null) => void;
  getAndSetSummaryForThread: (threadId: string, content: string) => Promise<void>;
  setToolStatus: (status: ToolStatus) => void;
  addActiveTool: (toolId: string) => void;
  removeActiveTool: (toolId: string) => void;
  clearActiveTools: () => void;
}

// This is now just a wrapper around the context hook
export const useAIState = (): readonly [AIState, AIStateActions] => {
  return useAIStateContext();
};
