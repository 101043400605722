import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { observer } from 'mobx-react-lite';
import { SnackbarProvider } from 'notistack';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import Routing from './Routing';
import logo from './assets/soilsense.png';
import ErrorBoundary from './components/ErrorBoundary';
import CustomizedSnackbar, { showInfoSnackBar } from './components/SnackBar';
import { useUserStore } from './dataHandlers/RootStore';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';

import type { PWAInstallElement } from '@khmyznikov/pwa-install';
import PWAInstall from '@khmyznikov/pwa-install/react-legacy';

// We don't apply the AIStateProvider at the App level anymore
// It will be moved to Routing.tsx or individual authenticated routes

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

declare global {
  interface Window {
    promptEvent: BeforeInstallPromptEvent | undefined;
  }
}

const App: React.FC = observer(() => {
  const userStore = useUserStore();
  const locale = userStore.locale;
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: string | number | undefined) => () => {
    if (notistackRef && notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  };

  // Update the state type
  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
  const pwaInstallRef = useRef<PWAInstallElement>(null);

  // externalPromptEvent is only if your app is big and slow to start
  // check index.html for additional code.
  // https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
  useEffect(() => {
    let lastPromptEvent = window.promptEvent || null;

    const intervalId = setInterval(() => {
      const currentPrompt = window.promptEvent || null;
      if (currentPrompt !== lastPromptEvent) {
        lastPromptEvent = currentPrompt;
        setPromptEvent(currentPrompt);
      }
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // display firebase notifications as a snackbar when the app
  // is on instead of sending a push notification
  React.useEffect(() => {
    return userStore.onMessage((payload) => {
      const message = payload.data?.text.split('Go to')[0];
      if (message != undefined) {
        showInfoSnackBar(message);
      }
    });
  }, [userStore]);

  return (
    <ErrorBoundary>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.ENGLISH}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <IconButton
                color='inherit'
                aria-label='dismiss pop up'
                onClick={onClickDismiss(key)}
                edge='start'
                size='large'
              >
                <CloseIcon />
              </IconButton>
            )}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            dense={false}
            preventDuplicate={true}
          >
            <CustomizedSnackbar />
            <BrowserRouter>
              {/* No AIStateProvider here - it will be moved inside Routing where user is available */}
              <Routing />
            </BrowserRouter>
            {/* <AddToHomeScreen /> */}
            <PWAInstall
              ref={pwaInstallRef}
              name={'SoilSense'}
              description={'Monitor your soil easily from anywhere'}
              icon={logo}
              externalPromptEvent={promptEvent as any}
              onPwaInstallAvailableEvent={(event: any) => console.log(event)}
              disableDescription={true}
            ></PWAInstall>
          </SnackbarProvider>
        </LocalizationProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
});

export default App;
