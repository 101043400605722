import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';
import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ChatTitleStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  fontSize: '1.2em',
  fontWeight: 'bold',
  borderBottom: '1px solid #e0e0e0',
  color: '#4f4f4f',
  backgroundColor: '#fdfdfd',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  textTransform: 'capitalize',
});

const getTooltipText = (isGeneralChat: boolean, intl: any) => {
  return intl.formatMessage({
    id: isGeneralChat ? 'chat_tooltip_agri' : 'chat_tooltip_field',
  });
};

export const ChatTitle: FC<{ generalChat: boolean; siteName?: string }> = ({ generalChat, siteName }) => {
  return (
    <Tooltip title={''} arrow placement='right'>
      <ChatTitleStyled>
        <FormattedMessage id={generalChat ? 'chat_title_agri' : 'chat_title_field'} />
        {siteName && ` - ${siteName}`}
      </ChatTitleStyled>
    </Tooltip>
  );
};
