import type { AssistantStream } from 'openai/lib/AssistantStream';
// @ts-expect-error - no types for this yet
import type { AssistantStreamEvent } from 'openai/resources/beta/assistants/assistants';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/runs/runs';
import type { ToolCall, ToolCallDelta } from 'openai/resources/beta/threads/runs/steps.mjs';
import { useCallback } from 'react';
import type { IntlShape } from 'react-intl';
import type { MessageProps } from '../Message';
import type { ToolStatus } from './useAIState';

export const useStreamHandlers = (
  setMessages: React.Dispatch<React.SetStateAction<MessageProps[]>>,
  setInputDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  submitActionResult: (
    runId: string,
    toolCallOutputs: { output: string; tool_call_id: string }[]
  ) => Promise<void>,
  functionCallHandler: (toolCall: RequiredActionFunctionToolCall) => Promise<string>,
  BASE_URL: string,
  setIsResponding: React.Dispatch<React.SetStateAction<boolean>>,
  intl: IntlShape,
  aiActions: {
    setToolStatus: (status: ToolStatus) => void;
    addActiveTool: (toolId: string) => void;
    removeActiveTool: (toolId: string) => void;
    clearActiveTools: () => void;
  }
): {
  handleReadableStream: (stream: AssistantStream) => void;
  appendMessage: (role: 'user' | 'assistant' | 'code', text: string) => void;
  appendToLastMessage: (text: string) => void;
  annotateLastMessage: (
    annotations: Array<{ type: string; text: string; file_path?: { file_id: string } }>
  ) => void;
} => {
  const appendToLastMessage = useCallback(
    (text: string) => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const updatedLastMessage = {
          ...lastMessage,
          text: lastMessage.text + text,
        };
        return [...prevMessages.slice(0, -1), updatedLastMessage];
      });
    },
    [setMessages]
  );

  const appendMessage = useCallback(
    (role: 'user' | 'assistant' | 'code', text: string) => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const thinkingMessage = intl.formatMessage({
          id: 'chat_thinking',
          defaultMessage: 'Thinking...',
        });
        if (lastMessage?.role === 'assistant' && lastMessage?.text === thinkingMessage) {
          return [...prevMessages.slice(0, -1), { role, text }];
        }
        return [...prevMessages, { role, text }];
      });
    },
    [setMessages, intl]
  );

  const annotateLastMessage = useCallback(
    (
      annotations: Array<{
        type: string;
        text: string;
        file_path?: { file_id: string };
        file_citation?: { file_id: string };
      }>
    ) => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const updatedLastMessage = { ...lastMessage };

        annotations.forEach((annotation) => {
          console.log({ annotation });
          if (annotation.type === 'file_path' && annotation.file_path) {
            updatedLastMessage.text = updatedLastMessage.text.replaceAll(
              annotation.text,
              `([source](#fileId=${annotation.file_path.file_id}))`
            );
          }

          if (annotation.type === 'file_citation' && annotation.file_citation) {
            updatedLastMessage.text = updatedLastMessage.text.replaceAll(
              annotation.text,
              ` ([source](#fileId=${annotation.file_citation.file_id}))`
            );
          }
        });

        return [...prevMessages.slice(0, -1), updatedLastMessage];
      });
    },
    [setMessages]
  );

  const handleTextCreated = useCallback(() => {
    appendMessage('assistant', '');
  }, [appendMessage]);

  const handleTextDelta = useCallback(
    (delta: { value?: string; annotations?: any }) => {
      if (delta.value != null) {
        appendToLastMessage(delta.value);
      }
      if (delta.annotations != null) {
        annotateLastMessage(
          delta.annotations as Array<{
            type: string;
            text: string;
            file_path?: { file_id: string };
          }>
        );
      }
    },
    [appendToLastMessage, annotateLastMessage]
  );

  const handleAnnotations = useCallback(
    (message: { annotations: Array<{ type: string; text: string; file_path?: { file_id: string } }> }) => {
      annotateLastMessage(message.annotations);
    },
    [annotateLastMessage]
  );

  const handleImageFileDone = useCallback(
    (image: { file_id: string }) => {
      appendToLastMessage(`\n![${image.file_id}](${BASE_URL}/llm/files/${image.file_id})\n`);
    },
    [BASE_URL, appendToLastMessage]
  );

  const toolCallCreated = useCallback(
    (toolCall: { type: string; id: string }) => {
      if (toolCall.type !== 'code_interpreter') return;
      appendMessage('code', '');
      // Update tool status when a code interpreter tool call is created
      const randomDelay = Math.floor(Math.random() * 500) + 500; // Random delay between 500ms and 1000ms
      setTimeout(() => {
        aiActions.addActiveTool('code_interpreter');
      }, randomDelay); // Delay to ensure the message is appended before updating the tool status
    },
    [appendMessage, aiActions]
  );

  const toolCallDelta = useCallback(
    (delta: ToolCallDelta, snapshot: ToolCall) => {
      if (delta.type !== 'code_interpreter') return;
      if (!delta.code_interpreter?.input) return;
      appendToLastMessage(delta.code_interpreter.input as string);
      // Keep the tool status active - now using addActiveTool instead of setting currentAction
      const randomDelay = Math.floor(Math.random() * 500) + 500; // Random delay between 500ms and 1000ms
      setTimeout(() => {
        aiActions.addActiveTool('code_interpreter');
      }, randomDelay); // Delay to ensure the message is appended before updating the tool status
    },
    [appendToLastMessage, aiActions]
  );

  const handleRequiresAction = useCallback(
    async (event: AssistantStreamEvent.ThreadRunRequiresAction) => {
      const runId = event.data.id as string;
      const toolCalls = event.data.required_action.submit_tool_outputs.tool_calls;

      const toolCallOutputs = await Promise.all(
        toolCalls.map(async (toolCall: { id: string; function: { name: string } }) => {
          console.log('TOOL CALL START:', toolCall.function.name);
          // Add this tool to active tools
          const randomDelay = Math.floor(Math.random() * 500) + 500; // Random delay between 500ms and 1000ms
          setTimeout(() => {
            aiActions.addActiveTool(toolCall.function.name);
          }, randomDelay); // Delay to ensure the message is appended before updating the tool status

          const result = await functionCallHandler(toolCall as RequiredActionFunctionToolCall);
          return { output: result, tool_call_id: toolCall.id };
        })
      );

      setInputDisabled(true);
      submitActionResult(runId, toolCallOutputs as { output: string; tool_call_id: string }[]);
    },
    [functionCallHandler, setInputDisabled, submitActionResult, aiActions]
  );

  const handleRunCompleted = useCallback(() => {
    setInputDisabled(false);
    setIsResponding(false);

    // Make sure tool status is cleared with delay
    console.log('Run completed, will clear tools in 3 seconds');
    setTimeout(() => {
      console.log('Clearing all active tools');
      aiActions.clearActiveTools();
    }, 3000);
  }, [setInputDisabled, setIsResponding, aiActions]);

  const handleRunFailed = useCallback(
    (event: { data: { last_error?: { message: string } } }) => {
      const errorMessage = event.data.last_error?.message || 'An error occurred during the conversation';
      appendMessage('assistant', `❌ ${errorMessage}`);
      setInputDisabled(false);
      setIsResponding(false);
      // Clear the tool status when the run fails - now using clearActiveTools
      aiActions.clearActiveTools();
    },
    [appendMessage, setInputDisabled, setIsResponding, aiActions]
  );

  const handleReadableStream = useCallback(
    (stream: AssistantStream) => {
      stream.on('error', (error) => {
        console.error('Stream error:', error);
        appendMessage('assistant', '❌ An error occurred while processing your request');
        setInputDisabled(false);
        setIsResponding(false);
      });

      stream.on('end', () => {
        console.log('Stream ended');
        setInputDisabled(false);
        setIsResponding(false);
      });

      stream.on('textCreated', handleTextCreated);
      stream.on('textDelta', handleTextDelta);
      stream.on('imageFileDone', handleImageFileDone);
      stream.on('toolCallCreated', toolCallCreated);
      stream.on('toolCallDelta', toolCallDelta);

      stream.on('event', (event) => {
        if (event.event === 'thread.run.created') {
          appendMessage(
            'assistant',
            intl.formatMessage({
              id: 'chat_thinking',
              defaultMessage: 'Thinking...',
            })
          );
        }
        if (event.event === 'thread.run.failed')
          handleRunFailed(event as { data: { last_error?: { message: string } } });
        if (event.event === 'thread.message.completed') {
          const messageData = event.data as unknown as {
            annotations: Array<{ type: string; text: string; file_path?: { file_id: string } }>;
          };
          if (messageData.annotations) {
            handleAnnotations(messageData);
          }
        }
        if (event.event === 'thread.run.requires_action') handleRequiresAction(event);
        if (event.event === 'thread.run.completed') handleRunCompleted();
      });
    },
    [
      setInputDisabled,
      handleTextCreated,
      handleTextDelta,
      handleImageFileDone,
      toolCallCreated,
      toolCallDelta,
      appendMessage,
      handleAnnotations,
      handleRequiresAction,
      handleRunCompleted,
      setIsResponding,
      handleRunFailed,
      intl,
    ]
  );

  return {
    handleReadableStream,
    appendMessage,
    appendToLastMessage,
    annotateLastMessage,
  };
};
