import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFarmStore, useFarmStore30Days, useFarmStore7Days } from '../../../dataHandlers/RootStore';

const isNumber = (value: any): value is number => typeof value === 'number';

export const useSoilMoistureData = (
  selectedSiteId: string,
  period?: '7days' | '30days' | null
): {
  processSoilMoistureData: () => Promise<{ lowestDate: string; highestDate: string; dailyData: any[] }>;
} => {
  const normalFarmStore = useFarmStore();
  const farmStore7Days = useFarmStore7Days();
  const farmStore30Days = useFarmStore30Days();
  const intl = useIntl();

  const aiFarmStore = useMemo(() => {
    return period === '7days' ? farmStore7Days : farmStore30Days;
  }, [period, farmStore7Days, farmStore30Days]);

  useEffect(() => {
    if (normalFarmStore.selectedFarmId !== aiFarmStore.selectedFarmId && normalFarmStore.selectedFarmId) {
      aiFarmStore.setSelectedFarmId(normalFarmStore.selectedFarmId);
    }
  }, [normalFarmStore.selectedFarmId, aiFarmStore]);

  const getData = useCallback(async () => {
    const site = aiFarmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (site) {
      console.log('getData site', site, selectedSiteId);
      const details = site.siteDetails;
      if (details.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    const field = aiFarmStore.areas
      .filter((area) => area.kind === 'field')
      .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));

    if (field) {
      console.log('getData field', field, selectedSiteId);
      const details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
      if (details?.transformed.status === 'ready') {
        return details.transformed.value;
      }
    }

    // console.log('getData undefined', selectedSiteId, farmStore.selectedFarm);
    return undefined;
  }, [aiFarmStore.areas, selectedSiteId]);

  const getDepthsAndCropInfo = useCallback(async () => {
    let details;
    const site = aiFarmStore.areas
      .filter((area) => area.kind === 'site')
      .find((area) => area.siteDetails.site.id === selectedSiteId);

    if (!site) {
      const field = aiFarmStore.areas
        .filter((area) => area.kind === 'field')
        .find((area) => area.fieldDetails.siteDetails.some((site) => site.site.id === selectedSiteId));
      if (!field) return { topDepth: undefined, bottomDepth: undefined, crop: undefined };
      details = field.fieldDetails.siteDetails.find((site) => site.site.id === selectedSiteId);
    } else {
      details = site.siteDetails;
    }

    if (!details) {
      return {
        topDepth: undefined,
        bottomDepth: undefined,
        crop: undefined,
        locationType: undefined,
        safeRangeHigh: undefined,
        safeRangeLow: undefined,
      };
    }

    return {
      topDepth: details.configuration.cableTop.depth,
      midDepth: details.configuration.cableMiddle?.depth,
      midBotDepth: details.configuration.cableMiddleBottom?.depth,
      bottomDepth: details.configuration.cableBottom.depth,
      crop: details.site.cropType,
      locationType: details.site.locationType,
      safeRangeHigh: details.site.safeRanges.plantAvailableWater?.[1],
      safeRangeLow: details.site.safeRanges.plantAvailableWater?.[0],
    };
  }, [aiFarmStore.areas, selectedSiteId]);

  const processSoilMoistureData = useCallback(async () => {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (
          aiFarmStore.areas
            .filter((area) => area.kind == 'site')
            .every((area) => area.siteDetails.transformed.status == 'ready')
        ) {
          clearInterval(interval);
          resolve(true);
        }
        if (
          aiFarmStore.areas
            .filter((area) => area.kind == 'site')
            .some((area) => area.siteDetails.transformed.status == 'error')
        ) {
          clearInterval(interval);
          resolve(true);
        }
      }, 500);
    });

    let data;
    try {
      data = await getData();
    } catch (error) {
      console.error(error);
      return {
        lowestDate: '',
        highestDate: '',
        dailyData: [],
      };
    }

    if (!data?.data) {
      return {
        lowestDate: '',
        highestDate: '',
        dailyData: [],
      };
    }

    const { topDepth, midDepth, midBotDepth, bottomDepth, crop, safeRangeHigh, safeRangeLow, locationType } =
      await getDepthsAndCropInfo();

    const hasPawData = data.data.some((item) => item.pawBot || item.pawTop);
    const hasVwcData = data.data.some((item) => item.vwcBot || item.vwcTop);

    if (!hasPawData && !hasVwcData)
      return {
        lowestDate: '',
        highestDate: '',
        dailyData: [],
      };

    // ... rest of the data processing logic ...
    // [Keep the existing dailyStats and dailyData processing logic here]
    // Group data by day
    const dailyStats = data.data.reduce(
      (
        acc: Record<string, any>, // Change the type to any temporarily
        item
      ) => {
        const day = moment(item.timestamp).format('YYYY-MM-DD');
        if (!item.pawBot && !item.pawTop && !item.pawMid && !item.vwcBot && !item.vwcTop && !item.vwcMid)
          return acc;

        if (!acc[day]) {
          acc[day] = hasPawData
            ? {
                pawTop: {
                  min: item.pawTop ?? '-',
                  max: item.pawTop ?? '-',
                  sum: item.pawTop ?? '-',
                  count: 1,
                },
                pawMid: {
                  min: item.pawMid ?? '-',
                  max: item.pawMid ?? '-',
                  sum: item.pawMid ?? '-',
                  count: 1,
                },
                pawMidBot: {
                  min: item.pawMidBot ?? '-',
                  max: item.pawMidBot ?? '-',
                  sum: item.pawMidBot ?? '-',
                  count: 1,
                },
                pawBot: {
                  min: item.pawBot ?? '-',
                  max: item.pawBot ?? '-',
                  sum: item.pawBot ?? '-',
                  count: 1,
                },
              }
            : {
                vwcTop: {
                  min: item.vwcTop ?? '-',
                  max: item.vwcTop ?? '-',
                  sum: item.vwcTop ?? '-',
                  count: 1,
                },
                vwcMid: {
                  min: item.vwcMid ?? '-',
                  max: item.vwcMid ?? '-',
                  sum: item.vwcMid ?? '-',
                  count: 1,
                },
                vwcMidBot: {
                  min: item.vwcMidBot ?? '-',
                  max: item.vwcMidBot ?? '-',
                  sum: item.vwcMidBot ?? '-',
                  count: 1,
                },
                vwcBot: {
                  min: item.vwcBot ?? '-',
                  max: item.vwcBot ?? '-',
                  sum: item.vwcBot ?? '-',
                  count: 1,
                },
              };
        } else {
          acc[day] = hasPawData
            ? {
                pawTop: {
                  min:
                    isNumber(acc[day].pawTop.min) && isNumber(item.pawTop ?? '-')
                      ? Math.min(acc[day].pawTop.min as number, item.pawTop!)
                      : acc[day].pawTop.min,
                  max:
                    isNumber(acc[day].pawTop.max) && isNumber(item.pawTop ?? '-')
                      ? Math.max(acc[day].pawTop.max as number, item.pawTop!)
                      : acc[day].pawTop.max,
                  sum:
                    isNumber(acc[day].pawTop.sum) && isNumber(item.pawTop ?? '-')
                      ? acc[day].pawTop.sum + item.pawTop!
                      : acc[day].pawTop.sum,
                  count: acc[day].pawTop.count + 1,
                },
                pawMid: {
                  min:
                    isNumber(acc[day].pawMid.min) && isNumber(item.pawMid ?? '-')
                      ? Math.min(acc[day].pawMid.min as number, item.pawMid!)
                      : acc[day].pawMid.min,
                  max:
                    isNumber(acc[day].pawMid.max) && isNumber(item.pawMid ?? '-')
                      ? Math.max(acc[day].pawMid.max as number, item.pawMid!)
                      : acc[day].pawMid.max,
                  sum:
                    isNumber(acc[day].pawMid.sum) && isNumber(item.pawMid ?? '-')
                      ? acc[day].pawMid.sum + item.pawMid!
                      : acc[day].pawMid.sum,
                  count: acc[day].pawMid.count + 1,
                },
                pawMidBot: {
                  min:
                    isNumber(acc[day].pawMidBot.min) && isNumber(item.pawMidBot ?? '-')
                      ? Math.min(acc[day].pawMidBot.min as number, item.pawMidBot!)
                      : acc[day].pawMidBot.min,
                  max:
                    isNumber(acc[day].pawMidBot.max) && isNumber(item.pawMidBot ?? '-')
                      ? Math.max(acc[day].pawMidBot.max as number, item.pawMidBot!)
                      : acc[day].pawMidBot.max,
                  sum:
                    isNumber(acc[day].pawMidBot.sum) && isNumber(item.pawMidBot ?? '-')
                      ? acc[day].pawMidBot.sum + item.pawMidBot!
                      : acc[day].pawMidBot.sum,
                  count: acc[day].pawMidBot.count + 1,
                },
                pawBot: {
                  min:
                    isNumber(acc[day].pawBot.min) && isNumber(item.pawBot ?? '-')
                      ? Math.min(acc[day].pawBot.min as number, item.pawBot!)
                      : acc[day].pawBot.min,
                  max:
                    isNumber(acc[day].pawBot.max) && isNumber(item.pawBot ?? '-')
                      ? Math.max(acc[day].pawBot.max as number, item.pawBot!)
                      : acc[day].pawBot.max,
                  sum:
                    isNumber(acc[day].pawBot.sum) && isNumber(item.pawBot ?? '-')
                      ? acc[day].pawBot.sum + item.pawBot!
                      : acc[day].pawBot.sum,
                  count: acc[day].pawBot.count + 1,
                },
              }
            : {
                vwcTop: {
                  min:
                    isNumber(acc[day].vwcTop.min) && isNumber(item.vwcTop ?? '-')
                      ? Math.min(acc[day].vwcTop.min as number, item.vwcTop!)
                      : acc[day].vwcTop.min,
                  max:
                    isNumber(acc[day].vwcTop.max) && isNumber(item.vwcTop ?? '-')
                      ? Math.max(acc[day].vwcTop.max as number, item.vwcTop!)
                      : acc[day].vwcTop.max,
                  sum:
                    isNumber(acc[day].vwcTop.sum) && isNumber(item.vwcTop ?? '-')
                      ? acc[day].vwcTop.sum + item.vwcTop!
                      : acc[day].vwcTop.sum,
                  count: acc[day].vwcTop.count + 1,
                },
                vwcMid: {
                  min:
                    isNumber(acc[day].vwcMid.min) && isNumber(item.vwcMid ?? '-')
                      ? Math.min(acc[day].vwcMid.min as number, item.vwcMid!)
                      : acc[day].vwcMid.min,
                  max:
                    isNumber(acc[day].vwcMid.max) && isNumber(item.vwcMid ?? '-')
                      ? Math.max(acc[day].vwcMid.max as number, item.vwcMid!)
                      : acc[day].vwcMid.max,
                  sum:
                    isNumber(acc[day].vwcMid.sum) && isNumber(item.vwcMid ?? '-')
                      ? acc[day].vwcMid.sum + item.vwcMid!
                      : acc[day].vwcMid.sum,
                  count: acc[day].vwcMid.count + 1,
                },
                vwcMidBot: {
                  min:
                    isNumber(acc[day].vwcMidBot.min) && isNumber(item.vwcMidBot ?? '-')
                      ? Math.min(acc[day].vwcMidBot.min as number, item.vwcMidBot!)
                      : acc[day].vwcMidBot.min,
                  max:
                    isNumber(acc[day].vwcMidBot.max) && isNumber(item.vwcMidBot ?? '-')
                      ? Math.max(acc[day].vwcMidBot.max as number, item.vwcMidBot!)
                      : acc[day].vwcMidBot.max,
                  sum:
                    isNumber(acc[day].vwcMidBot.sum) && isNumber(item.vwcMidBot ?? '-')
                      ? acc[day].vwcMidBot.sum + item.vwcMidBot!
                      : acc[day].vwcMidBot.sum,
                  count: acc[day].vwcMidBot.count + 1,
                },
                vwcBot: {
                  min:
                    isNumber(acc[day].vwcBot.min) && isNumber(item.vwcBot ?? '-')
                      ? Math.min(acc[day].vwcBot.min as number, item.vwcBot!)
                      : acc[day].vwcBot.min,
                  max:
                    isNumber(acc[day].vwcBot.max) && isNumber(item.vwcBot ?? '-')
                      ? Math.max(acc[day].vwcBot.max as number, item.vwcBot!)
                      : acc[day].vwcBot.max,
                  sum:
                    isNumber(acc[day].vwcBot.sum) && isNumber(item.vwcBot ?? '-')
                      ? acc[day].vwcBot.sum + item.vwcBot!
                      : acc[day].vwcBot.sum,
                  count: acc[day].vwcBot.count + 1,
                },
              };
        }
        return acc;
      },
      {}
    );

    const isPawStats = (
      stats: any
    ): stats is {
      pawTop: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
      pawMid: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
      pawMidBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
      pawBot: { min: number | '-'; max: number | '-'; sum: number | '-'; count: number };
    } => 'pawTop' in stats;

    const dailyData = Object.entries(dailyStats).map(([date, stats]) => ({
      date,
      crop: intl.formatMessage({ id: 'i_am_growing_crop', defaultMessage: 'I am growing {crop}' }, { crop }),
      locationType: intl.formatMessage(
        { id: 'my_location_type_is', defaultMessage: 'My location type is {locationType}' },
        { locationType }
      ),
      description: {
        pawTop: topDepth
          ? intl.formatMessage(
              {
                id: 'paw_depth_description',
                defaultMessage: 'Water available for plants ({depth}cm) in {soilLevel}',
              },
              {
                depth: topDepth,
                soilLevel: intl.formatMessage({ id: 'surface_soil', defaultMessage: 'surface_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'paw_depth_description_no_depth', defaultMessage: 'Water available for plants ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'surface_soil', defaultMessage: 'surface_soil' }) }
            ),
        pawMid: midDepth
          ? intl.formatMessage(
              {
                id: 'paw_depth_description',
                defaultMessage: 'Water available for plants ({depth}cm) in {soilLevel}',
              },
              {
                depth: midDepth,
                soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'paw_depth_description_no_depth', defaultMessage: 'Water available for plants ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }) }
            ),
        pawMidBot: midBotDepth
          ? intl.formatMessage(
              {
                id: 'paw_depth_description',
                defaultMessage: 'Water available for plants ({depth}cm) in {soilLevel}',
              },
              {
                depth: midBotDepth,
                soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'paw_depth_description_no_depth', defaultMessage: 'Water available for plants ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }) }
            ),
        pawBot: bottomDepth
          ? intl.formatMessage(
              {
                id: 'paw_depth_description',
                defaultMessage: 'Water available for plants ({depth}cm) in {soilLevel}',
              },
              {
                depth: bottomDepth,
                soilLevel: intl.formatMessage({ id: 'deep_soil', defaultMessage: 'deep_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'paw_depth_description_no_depth', defaultMessage: 'Water available for plants ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'deep_soil', defaultMessage: 'deep_soil' }) }
            ),
        vwcTop: topDepth
          ? intl.formatMessage(
              { id: 'vwc_depth_description', defaultMessage: 'Water content ({depth}cm) in {soilLevel}' },
              {
                depth: topDepth,
                soilLevel: intl.formatMessage({ id: 'surface_soil', defaultMessage: 'surface_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'vwc_depth_description_no_depth', defaultMessage: 'Water content ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'surface_soil', defaultMessage: 'surface_soil' }) }
            ),
        vwcMid: midDepth
          ? intl.formatMessage(
              { id: 'vwc_depth_description', defaultMessage: 'Water content ({depth}cm) in {soilLevel}' },
              {
                depth: midDepth,
                soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'vwc_depth_description_no_depth', defaultMessage: 'Water content ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }) }
            ),
        vwcMidBot: midBotDepth
          ? intl.formatMessage(
              { id: 'vwc_depth_description', defaultMessage: 'Water content ({depth}cm) in {soilLevel}' },
              {
                depth: midBotDepth,
                soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'vwc_depth_description_no_depth', defaultMessage: 'Water content ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'middle_soil', defaultMessage: 'middle_soil' }) }
            ),
        vwcBot: bottomDepth
          ? intl.formatMessage(
              { id: 'vwc_depth_description', defaultMessage: 'Water content ({depth}cm) in {soilLevel}' },
              {
                depth: bottomDepth,
                soilLevel: intl.formatMessage({ id: 'deep_soil', defaultMessage: 'deep_soil' }),
              }
            )
          : intl.formatMessage(
              { id: 'vwc_depth_description_no_depth', defaultMessage: 'Water content ({soilLevel})' },
              { soilLevel: intl.formatMessage({ id: 'deep_soil', defaultMessage: 'deep_soil' }) }
            ),
        pawSafeRangeHigh: intl.formatMessage(
          { id: 'paw_safe_range_high', defaultMessage: 'Safe range high ({value}%)' },
          { value: safeRangeHigh }
        ),
        pawSafeRangeLow: intl.formatMessage(
          { id: 'paw_safe_range_low', defaultMessage: 'Safe range low ({value}%)' },
          { value: safeRangeLow }
        ),
      },
      ...(isPawStats(stats)
        ? {
            pawTop: {
              min: stats.pawTop.min,
              max: stats.pawTop.max,
              avg:
                isNumber(stats.pawTop.sum) && isNumber(stats.pawTop.count)
                  ? stats.pawTop.sum / stats.pawTop.count
                  : '-',
            },
            pawMid: {
              min: stats.pawMid.min,
              max: stats.pawMid.max,
              avg:
                isNumber(stats.pawMid.sum) && isNumber(stats.pawMid.count)
                  ? stats.pawMid.sum / stats.pawMid.count
                  : '-',
            },
            pawMidBot: {
              min: stats.pawMidBot.min,
              max: stats.pawMidBot.max,
              avg:
                isNumber(stats.pawMidBot.sum) && isNumber(stats.pawMidBot.count)
                  ? stats.pawMidBot.sum / stats.pawMidBot.count
                  : '-',
            },
            pawBot: {
              min: stats.pawBot.min,
              max: stats.pawBot.max,
              avg:
                isNumber(stats.pawBot.sum) && isNumber(stats.pawBot.count)
                  ? stats.pawBot.sum / stats.pawBot.count
                  : '-',
            },
          }
        : {
            vwcTop: {
              min: stats.vwcTop.min,
              max: stats.vwcTop.max,
              avg:
                isNumber(stats.vwcTop.sum) && isNumber(stats.vwcTop.count)
                  ? stats.vwcTop.sum / stats.vwcTop.count
                  : '-',
            },
            vwcMid: {
              min: stats.vwcMid.min,
              max: stats.vwcMid.max,
              avg:
                isNumber(stats.vwcMid.sum) && isNumber(stats.vwcMid.count)
                  ? stats.vwcMid.sum / stats.vwcMid.count
                  : '-',
            },
            vwcMidBot: {
              min: stats.vwcMidBot.min,
              max: stats.vwcMidBot.max,
              avg:
                isNumber(stats.vwcMidBot.sum) && isNumber(stats.vwcMidBot.count)
                  ? stats.vwcMidBot.sum / stats.vwcMidBot.count
                  : '-',
            },
            vwcBot: {
              min: stats.vwcBot.min,
              max: stats.vwcBot.max,
              avg:
                isNumber(stats.vwcBot.sum) && isNumber(stats.vwcBot.count)
                  ? stats.vwcBot.sum / stats.vwcBot.count
                  : '-',
            },
          }),
    }));

    const lowestDate = dailyData.reduce((min, item) => {
      return moment(item.date).isBefore(moment(min.date)) ? item : min;
    }, dailyData[0]);

    const highestDate = dailyData.reduce((max, item) => {
      return moment(item.date).isAfter(moment(max.date)) ? item : max;
    }, dailyData[0]);

    return {
      lowestDate: lowestDate.date,
      highestDate: highestDate.date,
      dailyData,
    };
  }, [aiFarmStore.areas, getData, getDepthsAndCropInfo, intl]);

  return { processSoilMoistureData };
};
