import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import type { Coordinates } from '@soilsense/shared';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH } from '@soilsense/shared';
import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import deviceIdImage from '../../assets/installation/DeviceIdNew.png';
import gatewayResetImage from '../../assets/installation/GatewayReset.png';
import gatewayAntennasImage from '../../assets/installation/GatewaySetup.png';
import { useRootStore } from '../../dataHandlers/RootStore';
import type { DeviceName } from '../../firebase/DeviceNames';
import Accordion from '../Accordion';
import type { MidDepthValue } from '../Settings/SensorDepths';
import { useStyles } from '../Settings/styles';
import CoordinatesInput from './CoordinatesInput';
import type { IStep } from './CustomStepper';
import CustomStepper from './CustomStepper';
import DeviceNameInput from './DeviceNameInput';
import LocationConfigurationInput, { type LocationType } from './LocationConfigurationInput';
import { goToInstallationStep, resetDataLoggerStep, turnOnNbLoggerStep } from './commonSteps';
import useGeolocator from './useGeolocator';

const GatewayDone = () => {
  return (
    <div>
      <span>
        <FormattedMessage id='gateway_done_a' />
      </span>
      <br />
      <br />
      <span>
        <FormattedMessage id='gateway_done_b' />
      </span>
    </div>
  );
};

const SensorNodeDone = () => {
  return (
    <div>
      <span>
        <FormattedMessage id='sensor_done_a' />
      </span>
      <br />
      <br />
      <span>
        <FormattedMessage id='sensor_done_b' />
      </span>
    </div>
  );
};

const NbLoggerDone = () => {
  return (
    <div>
      <span>
        <FormattedMessage id='sensor_done_a' />
      </span>
      <br />
      <br />
      <span>
        <FormattedMessage id='nb_logger_done_b' />
      </span>
    </div>
  );
};

type DeviceParameters = Readonly<{
  deviceName: string;
  detectedDevice: DeviceName | undefined;
  coordinates: Partial<Coordinates>;
  name: string;
  type: string;
  topDepth: number | undefined;
  midDepth: MidDepthValue;
  midBotDepth: MidDepthValue;
  botDepth: number | undefined;
  cropType: string;
  locationType: LocationType | '';
}>;

const DEFAULT_DEVICE_PARAMETERS: DeviceParameters = {
  deviceName: '',
  detectedDevice: undefined,
  coordinates: {},
  name: '',
  type: '',
  topDepth: DEFAULT_TOP_DEPTH,
  midDepth: 'disabled',
  midBotDepth: 'disabled',
  botDepth: DEFAULT_BOT_DEPTH,
  cropType: '',
  locationType: '',
};

export type AddSensorCallback = (
  sensorId: string,
  deviceName: string,
  name: string,
  type: 'sensor' | 'nbsensor',
  location: Coordinates,
  topDepth: number,
  midDepth: number | undefined,
  midBotDepth: number | undefined,
  botDepth: number,
  locationType: LocationType | '',
  cropType?: string
) => void;

export type AddGatewayCallback = (
  id: string,
  deviceName: string,
  coordinates: Coordinates,
  nbsensor?: boolean
) => void;

export default function AddDeviceFlow({
  addNewSensor,
  addNewGateway,
  disabled,
  adding,
  setAdding,
  refetchAllStatuses,
  handleSettingsSave,
}: {
  addNewSensor: AddSensorCallback;
  addNewGateway: AddGatewayCallback;
  setAdding: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  adding: boolean;
  refetchAllStatuses: () => void;
  handleSettingsSave: (closeSettings: boolean) => void;
}): JSX.Element {
  const classes = useStyles();
  const rootStore = useRootStore();
  const intl = useIntl();

  const toggleAdding = useCallback(() => {
    setAdding((p) => !p);
  }, [setAdding]);

  const [stepNumber, setStepNumber] = useState(0);
  const incrementStepper = useCallback(() => setStepNumber((n) => n + 1), [setStepNumber]);
  const resetStepper = useCallback(() => setStepNumber(0), [setStepNumber]);

  const geolocator = useGeolocator();
  const [
    {
      deviceName,
      detectedDevice,
      coordinates,
      name,
      topDepth,
      midDepth,
      midBotDepth,
      botDepth,
      cropType,
      locationType,
    },
    setDeviceParameters,
  ] = useState(DEFAULT_DEVICE_PARAMETERS);
  console.log({
    deviceName,
    detectedDevice,
    coordinates,
    name,
    topDepth,
    midDepth,
    midBotDepth,
    botDepth,
    cropType,
    locationType,
  });
  const setDeviceName = useCallback(
    (deviceName: string) => setDeviceParameters((parameters) => ({ ...parameters, deviceName })),
    [setDeviceParameters]
  );
  const setDetectedDevice = useCallback(
    (detectedDevice: DeviceName) => setDeviceParameters((parameters) => ({ ...parameters, detectedDevice })),
    [setDeviceParameters]
  );
  const setCoordinates = useCallback(
    (coordinates: Partial<Coordinates>) => setDeviceParameters((parameters) => ({ ...parameters, coordinates })),
    [setDeviceParameters]
  );
  const setName = useCallback(
    (name: string) => setDeviceParameters((parameters) => ({ ...parameters, name })),
    [setDeviceParameters]
  );
  const setTopDepth = useCallback(
    (topDepth: number | undefined) => setDeviceParameters((parameters) => ({ ...parameters, topDepth })),
    [setDeviceParameters]
  );
  const setMidDepth = useCallback(
    (midDepth: MidDepthValue) => setDeviceParameters((parameters) => ({ ...parameters, midDepth })),
    [setDeviceParameters]
  );
  const setMidBotDepth = useCallback(
    (midBotDepth: MidDepthValue) => setDeviceParameters((parameters) => ({ ...parameters, midBotDepth })),
    [setDeviceParameters]
  );
  const setBotDepth = useCallback(
    (botDepth: number | undefined) => setDeviceParameters((parameters) => ({ ...parameters, botDepth })),
    [setDeviceParameters]
  );
  const setCropType = useCallback(
    (cropType: string) => setDeviceParameters((parameters) => ({ ...parameters, cropType })),
    [setDeviceParameters]
  );
  const setLocationType = useCallback(
    (locationType: LocationType) => setDeviceParameters((parameters) => ({ ...parameters, locationType })),
    [setDeviceParameters]
  );

  const onCancel = () => {
    setAdding(false);
    setTimeout(() => {
      geolocator.reset();
      setDeviceParameters(DEFAULT_DEVICE_PARAMETERS);
      resetStepper();
    }, 600); // delay to account for accordion closing animation
  };

  const commonSteps: IStep[] = [
    goToInstallationStep(intl),
    {
      title: intl.formatMessage({ id: 'provide_device_id' }),
      image: deviceIdImage,
      content: <DeviceNameInput deviceName={deviceName} setDeviceName={setDeviceName} />,
      canProceed: deviceName !== '',
      onNext: async () => {
        const sanitizedDeviceName = deviceName.trim();
        const deviceNameObject = await rootStore.fetchAssignableDevice(sanitizedDeviceName);
        if (deviceNameObject === undefined) {
          throw new Error(intl.formatMessage({ id: 'this_device_cannot_be_added' }));
        }
        setDetectedDevice(deviceNameObject);
      },
    },
    {
      title: intl.formatMessage({ id: 'provide_coordinates' }),
      content: (
        <CoordinatesInput coordinates={coordinates} setCoordinates={setCoordinates} geolocator={geolocator} />
      ),
      canProceed: coordinates.lat != undefined && coordinates.lng != undefined,
    },
  ];

  const gatewaySteps: IStep[] = [
    {
      title: intl.formatMessage({ id: 'connect_antennas_title' }),
      content: intl.formatMessage({ id: 'connect_antennas_description' }),
      canProceed: true,
      image: gatewayAntennasImage,
    },
    {
      title: intl.formatMessage({ id: 'reset_and_check_signal_title' }),
      content: intl.formatMessage({ id: 'reset_and_check_signal_description' }),
      canProceed: true,
      image: gatewayResetImage,
      onNext: async () => {
        const { lat, lng } = coordinates;
        if (lat == undefined || lng == undefined || detectedDevice == undefined) {
          throw new Error(intl.formatMessage({ id: 'gateway_could_not_be_added' }));
        }
        addNewGateway(detectedDevice.deviceId, detectedDevice.id, { lat, lng }, false);
        refetchAllStatuses();
      },
    },
    {
      title: intl.formatMessage({ id: 'done' }),
      content: <GatewayDone />,
      canProceed: true,
      onNext: async () => {
        handleSettingsSave(false);
      },
    },
  ];

  const nodeSteps: IStep[] = [
    resetDataLoggerStep(intl),
    {
      title: intl.formatMessage({ id: 'configure_location_title' }),
      content: (
        <LocationConfigurationInput
          name={name}
          setName={setName}
          topDepth={topDepth}
          setTopDepth={setTopDepth}
          midDepth={midDepth}
          setMidDepth={setMidDepth}
          midBotDepth={midBotDepth}
          setMidBotDepth={setMidBotDepth}
          botDepth={botDepth}
          setBotDepth={setBotDepth}
          cropType={cropType}
          setCropType={setCropType}
          locationType={locationType}
          setLocationType={setLocationType}
        />
      ),
      canProceed:
        name.trim() !== '' &&
        Boolean(cropType) &&
        topDepth != undefined &&
        midDepth != undefined &&
        botDepth != undefined,
      onNext: async () => {
        const { lat, lng } = coordinates;
        if (
          lat == undefined ||
          lng == undefined ||
          detectedDevice == undefined ||
          topDepth == undefined ||
          midDepth == undefined ||
          botDepth == undefined
        ) {
          throw new Error(intl.formatMessage({ id: 'data_logger_could_not_be_added' }));
        }
        if (!locationType) {
          throw new Error(intl.formatMessage({ id: 'location_type_not_selected' }));
        }
        addNewSensor(
          detectedDevice.deviceId,
          detectedDevice.id,
          name,
          detectedDevice.deviceType as 'sensor',
          { lat, lng },
          topDepth,
          midDepth == 'disabled' ? undefined : midDepth,
          midBotDepth == 'disabled' ? undefined : midBotDepth,
          botDepth,
          locationType,
          cropType
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'done' }),
      content: <SensorNodeDone />,
      canProceed: true,
      onNext: async () => {
        handleSettingsSave(false);
      },
    },
  ];

  const nbloggerNodeSteps: IStep[] = [
    turnOnNbLoggerStep(intl),
    {
      title: intl.formatMessage({ id: 'configure_location_title' }),
      content: (
        <LocationConfigurationInput
          name={name}
          setName={setName}
          topDepth={topDepth}
          setTopDepth={setTopDepth}
          midDepth={midDepth}
          setMidDepth={setMidDepth}
          midBotDepth={midBotDepth}
          setMidBotDepth={setMidBotDepth}
          botDepth={botDepth}
          setBotDepth={setBotDepth}
          cropType={cropType}
          setCropType={setCropType}
          locationType={locationType}
          setLocationType={setLocationType}
        />
      ),
      canProceed:
        name.trim() !== '' &&
        Boolean(cropType) &&
        topDepth != undefined &&
        midDepth != undefined &&
        midBotDepth != undefined &&
        botDepth != undefined &&
        Boolean(locationType),
      onNext: async () => {
        const { lat, lng } = coordinates;
        if (
          lat == undefined ||
          lng == undefined ||
          detectedDevice == undefined ||
          topDepth == undefined ||
          midDepth == undefined ||
          midBotDepth == undefined ||
          botDepth == undefined ||
          locationType == undefined
        ) {
          throw new Error(intl.formatMessage({ id: 'nb_data_logger_could_not_be_added' }));
        }
        addNewSensor(
          detectedDevice.deviceId,
          detectedDevice.id,
          name,
          detectedDevice.deviceType as 'nbsensor',
          { lat, lng },
          topDepth,
          midDepth == 'disabled' ? undefined : midDepth,
          midBotDepth == 'disabled' ? undefined : midBotDepth,
          botDepth,
          locationType,
          cropType
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'done' }),
      content: <NbLoggerDone />,
      canProceed: true,
      onNext: async () => {
        handleSettingsSave(false);
      },
    },
  ];

  const mode = detectedDevice?.deviceType;
  const unknownNumberOfSteps = mode == null;
  let steps = commonSteps;
  if (mode === 'gateway') {
    steps = [...commonSteps, ...gatewaySteps];
  }
  if (mode === 'sensor') {
    steps = [...commonSteps, ...nodeSteps];
  }
  // modify this once we know ho the flow should
  // look like for hte nbsensor
  if (mode === 'nbsensor') {
    steps = [...commonSteps, ...nbloggerNodeSteps];
  }

  const stepperWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (adding) {
      setTimeout(() => {
        stepperWrapperRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    }
  }, [adding, stepNumber]);

  return (
    <>
      <Accordion
        primarySummary={intl.formatMessage({ id: 'add_new_device' })}
        expanded={!disabled && adding}
        expandIcon={<AddCircleOutlineIcon />}
        onSummaryClick={toggleAdding}
        disabled={disabled}
      >
        <div className={classes.oneSensorWrapper} ref={stepperWrapperRef}>
          <CustomStepper
            activeStep={stepNumber}
            steps={steps}
            incrementStepper={incrementStepper}
            onCancel={onCancel}
            unknownNumberOfSteps={unknownNumberOfSteps}
          />
        </div>
      </Accordion>
    </>
  );
}
