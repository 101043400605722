import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCurrentUser, useFarmStore, useObservationSiteStore } from '../../dataHandlers/RootStore';
import { toSnakeCase } from '../../utils/toSnakeCase';
import SwitchWithLabel from '../Switch';
import type { IControls } from './config';
import { CONTROLS_ORDER, CONTROL_ICONS, CONTROL_OPTIONS } from './config';

interface IProps {
  controls: IControls;
  onChange: (key: CONTROL_OPTIONS) => void;
  disabled: boolean;
}

const Controls: FC<IProps> = observer(({ controls, onChange, disabled }) => {
  const farmStore = useFarmStore();
  const observationSiteStore = useObservationSiteStore();
  const currentUser = useCurrentUser();
  const intl = useIntl();
  const currentFarm = farmStore.selectedFarm;

  console.log('boxTemperature', currentFarm?.showBoxTemperature);

  const shouldRender = (key: string) => {
    switch (key) {
      case CONTROL_OPTIONS.Salinity:
        return observationSiteStore.atLeastOneSalinitySensor;
      case CONTROL_OPTIONS.BoxTemperature:
        // return observationSiteStore.atLeastOneBoxTemperatureSensor && !currentUser?.disableAirTemperatureChart;
        return currentFarm?.showBoxTemperature && !currentUser?.disableAirTemperatureChart;
      case CONTROL_OPTIONS.ArchivedLocations:
        return observationSiteStore.archivedSiteDetails.length > 0;
      case CONTROL_OPTIONS.Precipitation:
        return observationSiteStore.hasPrecipitation;
      default:
        return true;
    }
  };

  const alwaysSetTo = (key: string) => {
    switch (key) {
      case CONTROL_OPTIONS.BoxTemperature:
        return currentUser?.disableAirTemperatureChart === true ? false : undefined;
      default:
        return undefined;
    }
  };

  return (
    <>
      {CONTROLS_ORDER.map((key, index) => {
        if (!shouldRender(key)) {
          return null;
        }
        const val = alwaysSetTo(key);
        return (
          <React.Fragment key={key}>
            <ListItem>
              <ListItemIcon style={{ minWidth: 40 }}>
                {React.cloneElement(CONTROL_ICONS[key as keyof IControls], {
                  sx: { fontSize: '1.8rem' },
                })}
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({ id: `controls_${toSnakeCase(key.toLowerCase())}` }).toUpperCase()}
                primaryTypographyProps={{
                  style: {
                    textTransform: 'uppercase',
                    fontSize: '0.875rem',
                  },
                }}
              />
              <SwitchWithLabel
                checked={val ?? controls[key as keyof IControls]}
                disabled={disabled}
                onChange={() => onChange(key as keyof IControls)}
                labelPlacement='start'
              />
              <div style={{ paddingRight: 13 }}></div>
            </ListItem>
            <div
              style={{
                width: '100%',
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Divider
                sx={{
                  opacity: 0.5,
                  my: 0.5,
                }}
              />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
});

export default Controls;
