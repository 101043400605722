import { mdiShowerHead as irrigationIconPath, mdiWeatherPouring as rainIconPath } from '@mdi/js';
import type { Coordinates, ObservationSite, SoilDataSource } from '@soilsense/shared';
import type { AnnotationOptions } from 'chartjs-plugin-annotation';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import type { PrecipitationBuckets } from '../../../dataHandlers/Precipitation';
import type { NameMap, TransformedData } from '../../../dataHandlers/SensorTransformer';
import { MOISTURE_SAFE_RANGE } from '../../../dataHandlers/utils/constsAndTypes';
import { translateDethNames } from '../../../i18n/utils';
import { IRRIGATION_STATUS_COLOR } from '../../../utils/getMarkerColorFromReadings';
import type { HistoryState } from '../HistoryItemSelector';
import { getChartColors } from './Config';
import type {
  AggregateInfo,
  ChartZoom,
  DatasetSpec,
  RightAxisSpec,
  SafeRangeOptions,
  TimeSeriesSpec,
} from './TimeSeriesChart';
import { TimeSeriesChart } from './TimeSeriesChart';
import { calculateNightTimeAnnotations } from './utils/calculateNightTimeAnnotations';

export type SensorChartData = Readonly<{
  data: readonly TransformedData[];
  rainfall: PrecipitationBuckets;
  irrigation: PrecipitationBuckets;
}>;

type SafeRanges = ObservationSite['safeRanges'];

type Props = {
  sensorChartData: SensorChartData;
  safeRanges: SafeRanges;
  chartZoom: ChartZoom;
  displayMidDepth: boolean;
  displayMidBotDepth: boolean;
  nameMap: NameMap;
  coordinates: Coordinates;
  showPlantAvailableWater: boolean;
  displayPrecipitation: boolean;
  yLabel: string;
  yMax: number | undefined;
  yMin: number | undefined;
  useSingleChart: boolean;
  soilDataSourceState?: SoilDataSourceState;
};

type AnnotatedDatasetSpec = {
  datasetSpec: DatasetSpec;
  annotations: AnnotationOptions[];
};

type SoilDataSourceState = HistoryState<SoilDataSource | undefined>;

export const ChartJsMoistureChart: React.FC<Props> = observer(
  ({
    sensorChartData,
    safeRanges,
    chartZoom,
    displayMidDepth,
    displayMidBotDepth,
    nameMap,
    coordinates,
    showPlantAvailableWater,
    displayPrecipitation,
    yLabel,
    yMax,
    yMin,
    useSingleChart,
    soilDataSourceState,
  }) => {
    const { data, rainfall, irrigation } = sensorChartData;
    const intl = useIntl();

    const timeSeriesSpecs = useMemo((): readonly TimeSeriesSpec[] => {
      const { moistureColor, safeRangeColor, safeRangeBackgroundColor } = getChartColors();
      const Y_LABEL = intl.formatMessage({ id: 'controls_precipitation' });
      const Y_LABEL_RAINFALL = intl.formatMessage({ id: 'rainfall' });
      const Y_LABEL_IRRIGATION = intl.formatMessage({ id: 'irrigation' });
      const labels: number[] = [];
      const topSpec: AnnotatedDatasetSpec = {
        datasetSpec: {
          label: showPlantAvailableWater
            ? translateDethNames(nameMap.pawTop, intl)
            : translateDethNames(nameMap.vwcTop, intl),
          data: [],
          color: moistureColor.cableTop,
        },
        annotations: [],
      };
      const midSpec: AnnotatedDatasetSpec = {
        datasetSpec: {
          label: showPlantAvailableWater
            ? translateDethNames(nameMap.pawMid, intl)
            : translateDethNames(nameMap.vwcMid, intl),
          data: [],
          color: moistureColor.cableMiddle,
        },
        annotations: [],
      };
      const midBotSpec: AnnotatedDatasetSpec = {
        datasetSpec: {
          label: showPlantAvailableWater
            ? translateDethNames(nameMap.pawMidBot, intl)
            : translateDethNames(nameMap.vwcMidBot, intl),
          data: [],
          color: moistureColor.cableMiddleBottom,
        },
        annotations: [],
      };
      const botSpec: AnnotatedDatasetSpec = {
        datasetSpec: {
          label: showPlantAvailableWater
            ? translateDethNames(nameMap.pawBot, intl)
            : translateDethNames(nameMap.vwcBot, intl),
          data: [],
          color: moistureColor.cableBottom,
        },
        annotations: [],
      };
      console.log({ data });
      for (const each of data) {
        labels.push(each.timestamp);
        topSpec.datasetSpec.data.push((showPlantAvailableWater ? each.pawTop : each.vwcTop) ?? null);
        midSpec.datasetSpec.data.push((showPlantAvailableWater ? each.pawMid : each.vwcMid) ?? null);
        midBotSpec.datasetSpec.data.push((showPlantAvailableWater ? each.pawMidBot : each.vwcMidBot) ?? null);
        botSpec.datasetSpec.data.push((showPlantAvailableWater ? each.pawBot : each.vwcBot) ?? null);
      }

      let labelIndex = 0;
      for (const each of rainfall) {
        if (each.endTimestamp < labels[0]) {
          continue;
        }
        if (each.endTimestamp > labels[labels.length - 1]) {
          continue;
        }
        while (labelIndex < labels.length && labels[labelIndex] < each.endTimestamp) {
          labelIndex++;
        }
        if (labelIndex < labels.length && labels[labelIndex] == each.endTimestamp) {
          continue;
        }
        labels.splice(labelIndex, 0, each.endTimestamp);
        topSpec.datasetSpec.data.splice(labelIndex, 0, null);
        midSpec.datasetSpec.data.splice(labelIndex, 0, null);
        midBotSpec.datasetSpec.data.splice(labelIndex, 0, null);
        botSpec.datasetSpec.data.splice(labelIndex, 0, null);
      }
      const firstTimestamp = moment(labels[0]);
      const lastTimestamp = moment(labels[labels.length - 1]);

      if (soilDataSourceState != undefined && showPlantAvailableWater == false) {
        topSpec.annotations = calculateCalibrationHistoryAnnotations(
          firstTimestamp,
          lastTimestamp,
          soilDataSourceState,
          'cableTop'
        );
        midSpec.annotations = calculateCalibrationHistoryAnnotations(
          firstTimestamp,
          lastTimestamp,
          soilDataSourceState,
          'cableMiddle'
        );
        midBotSpec.annotations = calculateCalibrationHistoryAnnotations(
          firstTimestamp,
          lastTimestamp,
          soilDataSourceState,
          'cableMiddleBottom'
        );
        botSpec.annotations = calculateCalibrationHistoryAnnotations(
          firstTimestamp,
          lastTimestamp,
          soilDataSourceState,
          'cableBottom'
        );
      }

      const annotatedDatasetSpecs = displayMidBotDepth
        ? [topSpec, midSpec, midBotSpec, botSpec]
        : displayMidDepth
        ? [topSpec, midSpec, botSpec]
        : [topSpec, botSpec];

      const explodedRainfallData: number[] = explodePrecipitationData(rainfall, labels);
      const explodedIrrigationData: number[] = explodePrecipitationData(irrigation, labels);
      const rightAxisSpec: RightAxisSpec | undefined =
        displayPrecipitation == false || (rainfall.length == 0 && irrigation.length == 0)
          ? undefined
          : {
              datasetSpecs: ([] as DatasetSpec[]).concat(
                rainfall.length === 0
                  ? []
                  : [
                      {
                        label: Y_LABEL_RAINFALL,
                        data: explodedRainfallData,
                        color: 'rgba(50, 255, 255, 0.3)',
                      },
                    ],
                irrigation.length === 0
                  ? []
                  : [
                      {
                        label: Y_LABEL_IRRIGATION,
                        data: explodedIrrigationData,
                        color: 'rgba(80, 225, 255, 0.3)',
                      },
                    ]
              ),
              yLabel: Y_LABEL,
              yUnit: 'mm',
            };

      const safeRangeBottom = showPlantAvailableWater
        ? safeRanges.plantAvailableWater?.[0]
        : safeRanges.volumetricWaterContent?.[0];
      const safeRangeTop = showPlantAvailableWater
        ? safeRanges.plantAvailableWater?.[1]
        : safeRanges.volumetricWaterContent?.[1];
      const safeRangeOptions: SafeRangeOptions | undefined =
        safeRangeBottom != undefined && safeRangeTop != undefined
          ? {
              range: [safeRangeBottom, safeRangeTop],
              label: intl.formatMessage({ id: 'moisture_safe_range', defaultMessage: MOISTURE_SAFE_RANGE }),
              borderColor: safeRangeColor,
              backgroundColor: safeRangeBackgroundColor,
            }
          : undefined;

      const timeUnit = lastTimestamp.diff(firstTimestamp, 'day') <= 3 ? 'hour' : 'day';
      const [nightTimeAnnotations] = calculateNightTimeAnnotations(
        moment(chartZoom.bounds?.[0] ?? firstTimestamp),
        moment(chartZoom.bounds?.[1] ?? lastTimestamp),
        coordinates
      );

      const bounds: readonly [number, number] = chartZoom.bounds ?? [labels[0], labels[labels.length - 1]];
      const aggregates: AggregateInfo[] = ([] as AggregateInfo[]).concat(
        displayPrecipitation && rainfall.length > 0
          ? [
              {
                iconPath: rainIconPath,
                iconColor: 'rgb(50, 255, 255)',
                title: intl.formatMessage({ id: 'total_rainfall', defaultMessage: 'Total rainfall' }),
                value: aggregatePrecipitation(rainfall, bounds),
                unit: 'mm',
              },
            ]
          : [],
        displayPrecipitation && irrigation.length > 0
          ? [
              {
                iconPath: irrigationIconPath,
                iconColor: 'rgb(80, 225, 255)',
                title: intl.formatMessage({ id: 'total_irrigation', defaultMessage: 'Total irrigation' }),
                value: aggregatePrecipitation(irrigation, bounds),
                unit: 'mm',
              },
            ]
          : []
      );

      return useSingleChart
        ? [
            {
              labels,
              chartZoom,
              datasetSpecs: annotatedDatasetSpecs.map(({ datasetSpec }) => datasetSpec),
              rightAxisSpec,
              safeRangeOptions,
              timeUnit,
              annotations: ([] as AnnotationOptions[]).concat(
                ...annotatedDatasetSpecs.map(({ annotations }) => annotations),
                nightTimeAnnotations
              ),
              aggregates,
            },
          ]
        : annotatedDatasetSpecs.map(({ annotations, datasetSpec }) => ({
            labels,
            chartZoom,
            datasetSpecs: [datasetSpec],
            rightAxisSpec,
            safeRangeOptions,
            timeUnit,
            annotations: [...annotations, ...nightTimeAnnotations],
            aggregates,
          }));
    }, [
      showPlantAvailableWater,
      nameMap.pawTop,
      nameMap.vwcTop,
      nameMap.pawMid,
      nameMap.vwcMid,
      nameMap.pawMidBot,
      nameMap.vwcMidBot,
      nameMap.pawBot,
      nameMap.vwcBot,
      soilDataSourceState,
      displayMidDepth,
      displayMidBotDepth,
      rainfall,
      irrigation,
      displayPrecipitation,
      safeRanges.plantAvailableWater,
      safeRanges.volumetricWaterContent,
      chartZoom,
      coordinates,
      useSingleChart,
      data,
      intl,
    ]);

    // this is a rather ugly hack to prevent "repeated" double click events
    // after the chart gets re-rendered; it is not quite clear to me why the
    // events actually get handled several times but the different event
    // handlers - perhaps the problem is in how we use Chart.js
    const lastDoubleClick = useRef<number>();
    const minimumDoubleClickDelayMs = 1000;

    return (
      <>
        {timeSeriesSpecs.map((spec, index) => (
          <TimeSeriesChart
            {...spec}
            key={index}
            yLabel={yLabel}
            yMin={yMin}
            yMax={yMax}
            yUnit='%'
            small={useSingleChart == false}
            showCrosshair={soilDataSourceState != undefined}
            flattenXAxisLabels={soilDataSourceState != undefined}
            onClick={
              soilDataSourceState == undefined || soilDataSourceState.disabled
                ? undefined
                : (coordinates) => {
                    for (const entry of Array.from(soilDataSourceState.history.entries())) {
                      const [index, item] = entry;
                      if (item.nextStartTimestamp == undefined || item.nextStartTimestamp > coordinates.x) {
                        soilDataSourceState.setSelectedIndex(index);
                        return;
                      }
                    }
                  }
            }
            onDoubleClick={
              soilDataSourceState == undefined || soilDataSourceState.disabled
                ? undefined
                : (coordinates) => {
                    const now = Date.now();
                    if (
                      lastDoubleClick.current == undefined ||
                      now - lastDoubleClick.current >= minimumDoubleClickDelayMs
                    ) {
                      lastDoubleClick.current = now;
                      soilDataSourceState.insertItem(coordinates.x);
                    }
                  }
            }
          />
        ))}
      </>
    );
  }
);

function calculateCalibrationHistoryAnnotations(
  firstTimestamp: Moment,
  lastTimestamp: Moment,
  soilDataSourceState: SoilDataSourceState,
  sensorSlot: 'cableTop' | 'cableMiddle' | 'cableMiddleBottom' | 'cableBottom'
): AnnotationOptions[] {
  const result: AnnotationOptions[] = [];

  const { history, selectedIndex } = soilDataSourceState;

  for (let i = 0; i < history.length; i++) {
    const currentItem = history[i];
    if (currentItem.hidden) {
      continue;
    }
    const calibration = currentItem?.value?.configuration[sensorSlot]?.calibration;

    const start = moment.max(firstTimestamp, moment(currentItem.startTimestamp));
    const end = moment.min(lastTimestamp, moment(currentItem.nextStartTimestamp ?? lastTimestamp));
    if (i == selectedIndex) {
      result.push({
        type: 'box',
        xMin: start.valueOf(),
        xMax: end.valueOf(),
        borderColor: 'rgba(0, 0, 0, 0)',
        backgroundColor: 'rgba(255, 255, 0, 0.08)',
      });
    }
    if (i > 0) {
      result.push({
        type: 'line',
        xMin: start.valueOf(),
        xMax: start.valueOf(),
        borderColor: IRRIGATION_STATUS_COLOR.dry,
      });
    }
    if (i < history.length - 1) {
      result.push({
        type: 'line',
        xMin: end.valueOf(),
        xMax: end.valueOf(),
        borderColor: IRRIGATION_STATUS_COLOR.dry,
      });
    }

    if (calibration != undefined) {
      const { fieldCapacity, wiltingPoint } = calibration;
      result.push({
        type: 'line',
        xMin: start.valueOf(),
        xMax: end.valueOf(),
        yMin: fieldCapacity,
        yMax: fieldCapacity,
        borderColor: IRRIGATION_STATUS_COLOR.ok,
      });
      result.push({
        type: 'line',
        xMin: start.valueOf(),
        xMax: end.valueOf(),
        yMin: wiltingPoint,
        yMax: wiltingPoint,
        borderColor: IRRIGATION_STATUS_COLOR.wilting,
      });
    }
  }

  return result;
}

function explodePrecipitationData(buckets: PrecipitationBuckets, labels: readonly number[]): number[] {
  const result: number[] = [];
  let bucketIndex = 0;
  let labelIndex = 0;
  while (bucketIndex < buckets.length) {
    const { endTimestamp, mm } = buckets[bucketIndex];
    while (labelIndex < labels.length && labels[labelIndex] < endTimestamp) {
      result.push(mm);
      labelIndex++;
    }
    bucketIndex++;
  }
  return result;
}

function aggregatePrecipitation(
  buckets: PrecipitationBuckets,
  chartZoomBounds: readonly [number, number]
): number {
  let result = 0;
  let bucketIndex = 0;
  while (bucketIndex < buckets.length) {
    const { endTimestamp, mm } = buckets[bucketIndex];
    if (endTimestamp > chartZoomBounds[0]) {
      result += mm;
    }
    if (endTimestamp >= chartZoomBounds[1]) {
      break;
    }
    bucketIndex++;
  }
  return result;
}
