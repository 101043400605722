'use client';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { AssistantStream } from 'openai/lib/AssistantStream';
import type { RequiredActionFunctionToolCall } from 'openai/resources/beta/threads/index.mjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useObservationSiteStore } from '../../dataHandlers/RootStore';
import theme from '../../theme';
import { BASE_URL } from '../../utils/consts';
import { useAIState, type ToolStatus } from './hooks/useAIState';
import { useStreamHandlers } from './hooks/useStreamHandlers';
import { Message, Messages, type MessageProps } from './Message';
import { useFetchWithAuth } from './utils/fetchWithAuth';

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const InputForm = styled('form')({
  display: 'flex',
  width: '100%',
  padding: '20px',
  backgroundColor: 'white',
  borderTop: '1px solid rgba(0, 0, 0, 0.08)',
  zIndex: 10,
  flexShrink: 0,
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 20px',
  },
});

const Input = styled('textarea')({
  flexGrow: 1,
  padding: '16px 24px',
  borderRadius: '10px',
  border: '2px solid transparent',
  fontSize: '1em',
  backgroundColor: '#efefef',
  resize: 'none',
  '&:focus': {
    outline: 'none',
    borderColor: '#000',
    backgroundColor: 'white',
  },
  '@media (max-width: 600px)': {
    marginRight: 0,
  },
});

const Button = styled('button')({
  padding: '8px 24px',
  backgroundColor: '#000',
  color: 'white',
  border: 'none',
  fontSize: '1em',
  borderRadius: '10px',
  '&:disabled': {
    backgroundColor: 'lightgrey',
  },
});

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
});

const ChatContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  height: '0', // Add height: 0 to force child elements to respect the flex constraints
  minHeight: '100%', // Ensure it takes full height of the parent
});

interface ChatProps {
  functionCallHandler?: (toolCall: RequiredActionFunctionToolCall) => Promise<string>;
  initialMessage?: string;
  generalChat?: boolean;
  threadId?: string;
  onThreadCreated?: () => void;
  getAndSetSummaryForThread?: (threadId: string, content: string) => Promise<void>;
  onError?: (error: Error | { message: string }) => void;
  selectedSiteId?: string;
  setToolStatus?: (status: ToolStatus) => void;
  toolStatusHistory?: Array<{ time: Date; status: ToolStatus }>;
}

const Chat = ({
  functionCallHandler = () => Promise.resolve(''),
  initialMessage,
  generalChat,
  threadId: providedThreadId,
  onThreadCreated,
  getAndSetSummaryForThread,
  onError,
  selectedSiteId,
  setToolStatus,
  toolStatusHistory = [],
}: ChatProps): JSX.Element => {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [threadId, setThreadId] = useState('');
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [initialMessageSent, setInitialMessageSent] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrolledToBottomOnce, setScrolledToBottomOnce] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [creatingThread, setCreatingThread] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second
  const intl = useIntl();
  const observationSiteStore = useObservationSiteStore();
  const [aiState, aiActions] = useAIState();

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: isResponding ? 'auto' : 'smooth' });
    }
  }, [messagesEndRef, isResponding]);

  const messageBasePath = useMemo(
    () => (generalChat ? `${BASE_URL}/llm/threads/generalChat` : `${BASE_URL}/llm/threads`),
    [generalChat]
  );

  const fetchWithAuth = useFetchWithAuth();

  const submitActionResult = useCallback(
    async (runId: string, toolCallOutputs: { output: string; tool_call_id: string }[]) => {
      const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${threadId}/actions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          runId: runId,
          toolCallOutputs: toolCallOutputs,
        }),
      });
      const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
      handleReadableStream(stream);
    },
    [threadId, fetchWithAuth] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { handleReadableStream, appendMessage } = useStreamHandlers(
    setMessages,
    setInputDisabled,
    submitActionResult,
    functionCallHandler,
    BASE_URL,
    setIsResponding,
    intl,
    aiActions
  );

  const messageCache = useMemo(() => new Map<string, MessageProps[]>(), []);

  const sendMessage = useCallback(
    async (text: string, threadId: string) => {
      let currentRetry = 0;

      const attemptSend = async (): Promise<void> => {
        try {
          console.log(`Sending a new message to a thread: ${threadId}`);
          const userMessages = messages.filter((msg) => msg.role === 'user');

          if (userMessages.length === 0 && getAndSetSummaryForThread) {
            await getAndSetSummaryForThread(threadId, text);
          }

          const response = await fetchWithAuth(`${messageBasePath}/${threadId}/messages`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              content: text,
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const stream = AssistantStream.fromReadableStream(response.body as ReadableStream<Uint8Array>);
          handleReadableStream(stream);
        } catch (error) {
          console.error(`Error sending message (attempt ${currentRetry + 1}/${MAX_RETRIES}):`, error);

          if (currentRetry < MAX_RETRIES) {
            currentRetry++;
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY * currentRetry));
            return attemptSend();
          }

          appendMessage(
            'assistant',
            intl.formatMessage({
              id: 'chat_error_message',
              defaultMessage: '❌ Failed to send message. Please try again.',
            })
          );
          setInputDisabled(false);
          setIsResponding(false);
          onError?.(error instanceof Error ? error : new Error('Failed to send message'));
        }
      };

      await attemptSend();
    },
    [
      fetchWithAuth,
      handleReadableStream,
      appendMessage,
      setInputDisabled,
      setIsResponding,
      messageBasePath,
      getAndSetSummaryForThread,
      messages,
      onError,
      intl,
    ]
  );

  const createThread = useCallback(async () => {
    if (creatingThread) return;
    let currentRetry = 0;

    const attemptCreate = async (): Promise<void> => {
      try {
        setCreatingThread(true);
        const res = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chatType: generalChat ? 'general' : 'irrigation',
            siteId: selectedSiteId,
          }),
        });

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = (await res.json()) as { threadId: string };
        setThreadId(data.threadId);
        onThreadCreated?.();
        await new Promise((resolve) => setTimeout(resolve, 500));
        setInputDisabled(false);
        setIsLoading(false);
        setCreatingThread(false);

        if (generalChat) {
          appendMessage(
            'assistant',
            intl.formatMessage(
              {
                id: 'ai_initial_message_general',
                defaultMessage: "Hi! I'm your assistant in helping with general agricultural questions...",
              },
              {
                assistant: `"${intl.formatMessage({
                  id: 'chat_title_field_short',
                })}"`,
              }
            )
          );
        }
      } catch (error) {
        console.error(`Error creating thread (attempt ${currentRetry + 1}/${MAX_RETRIES}):`, error);

        if (currentRetry < MAX_RETRIES) {
          currentRetry++;
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY * currentRetry));
          return attemptCreate();
        }

        appendMessage(
          'assistant',
          intl.formatMessage({
            id: 'chat_error_thread_creation',
            defaultMessage: '❌ Failed to start conversation. Please refresh the page.',
          })
        );
        setInputDisabled(true);
        setIsLoading(false);
        setCreatingThread(false);
        onError?.(error instanceof Error ? error : new Error('Failed to create thread'));
      }
    };

    await attemptCreate();
  }, [
    creatingThread,
    fetchWithAuth,
    generalChat,
    selectedSiteId,
    setThreadId,
    onThreadCreated,
    setInputDisabled,
    setIsLoading,
    appendMessage,
    intl,
    onError,
  ]);

  useEffect(() => {
    const run = async () => {
      const loadExistingThread = async () => {
        try {
          if (providedThreadId && messageCache.has(providedThreadId)) {
            const cachedMessages = messageCache.get(providedThreadId)!;
            setMessages(cachedMessages);

            console.log({ cachedMessages });

            // Add welcome message for empty general chat
            if (generalChat && cachedMessages.length === 0) {
              appendMessage(
                'assistant',
                intl.formatMessage(
                  {
                    id: 'ai_initial_message_general',
                    defaultMessage:
                      "Hi! I'm your assistant in helping with general agricultural questions. You can ask me anything related to farming and as long as I have access to knowledge that answers your question I will do my best to assist you. I don't have access to your data - for that, please start a new chat and select the Irrigation Assistant. How may I assist you today?",
                  },
                  {
                    assistant: `"${intl.formatMessage({
                      id: 'chat_title_field_short',
                    })}"`,
                  }
                )
              );
            }

            setThreadId(providedThreadId);
            setInputDisabled(false);
            setIsLoading(false);
            return;
          }

          const response = await fetchWithAuth(`${BASE_URL}/llm/threads/${providedThreadId}/messages`, {
            method: 'GET',
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const respJson = await response.json();
          const thread = respJson.thread;
          const messages = respJson.messages.data.map((msg: any) => ({
            role: msg.role as MessageProps['role'],
            text: msg.content.map((content: any) => content.text.value).join(''),
            created_at: msg.created_at,
            content: msg.content,
          }));

          const filteredMessages = messages
            .filter((msg: MessageProps) => !msg.text.includes('__initial_message__'))
            .reverse() as MessageProps[];

          // Add welcome message for empty general chat
          if (generalChat && filteredMessages.length === 0) {
            filteredMessages.push({
              role: 'assistant',
              text: intl.formatMessage(
                {
                  id: 'ai_initial_message_general',
                  defaultMessage:
                    "Hi! I'm your assistant in helping with general agricultural questions. You can ask me anything related to farming and as long as I have access to knowledge that answers your question I will do my best to assist you. I don't have access to your data - for that, please start a new chat and select the Irrigation Assistant. How may I assist you today?",
                },
                {
                  assistant: `"${intl.formatMessage({
                    id: 'chat_title_field_short',
                  })}"`,
                }
              ),
            });
          }

          console.log({ filteredMessages });

          messageCache.set(providedThreadId!, filteredMessages);
          setMessages(filteredMessages);
          setThreadId(providedThreadId || '');
          setInputDisabled(false);
          setIsLoading(false);
        } catch (error) {
          console.error('Error loading thread:', error);
          appendMessage('assistant', '❌ Failed to load conversation. Please try again.');
          setInputDisabled(true);
          setIsLoading(false);
          onError?.(error instanceof Error ? error : new Error('Failed to load conversation'));
        }
      };

      if (providedThreadId && !threadId) {
        await loadExistingThread();
      } else if (!threadId) {
        const timeoutId = setTimeout(() => {
          setIsLoading(false);
        }, 10000);

        if (!creatingThread) {
          await createThread();
        }

        return () => clearTimeout(timeoutId);
      }

      if (threadId && initialMessage && !initialMessageSent && !generalChat && !isLoading && !creatingThread) {
        if (messages.length === 0) {
          setIsResponding(true);
          sendMessage(`__initial_message__ ${initialMessage}`, threadId);
          setInitialMessageSent(true);
          setInputDisabled(true);
          setUserInput('');
          scrollToBottom();
          appendMessage(
            'assistant',
            intl.formatMessage({
              id: 'chat_thinking',
              defaultMessage: 'Thinking...',
            })
          );
        }
      }
    };
    run();
  }, [
    fetchWithAuth,
    initialMessage,
    sendMessage,
    appendMessage,
    initialMessageSent,
    threadId,
    generalChat,
    providedThreadId,
    messageBasePath,
    onThreadCreated,
    messages.length,
    isLoading,
    scrollToBottom,
    messageCache,
    creatingThread,
    onError,
    intl,
    selectedSiteId,
    createThread,
  ]);

  useEffect(() => {
    console.log({ initialMessageSent, isResponding, isLoading });
    if (initialMessageSent && !isResponding && !isLoading) {
      setInputDisabled(false);
    }
  }, [initialMessageSent, isResponding, isLoading]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userInput.trim() || isResponding) return;
    sendMessage(userInput, threadId);
    appendMessage('user', userInput);
    setUserInput('');
    setInputDisabled(true);
    setIsResponding(true);
    scrollToBottom();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!inputDisabled && userInput.trim()) {
        handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
      }
    }
  };

  const siteName = selectedSiteId ? observationSiteStore.getSiteName(selectedSiteId) : undefined;

  return (
    <>
      <ChatContainer>
        {isLoading && messages.length === 0 ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <ChatContentContainer>
            <Messages onIsAtBottomChange={setIsAtBottom} style={{ flexGrow: 1, overflow: 'auto', minHeight: 0 }}>
              {messages.map((msg, index) => (
                <Message
                  key={index}
                  role={msg.role as 'user' | 'assistant' | 'code'}
                  activeTools={aiState.toolStatus.activeTools}
                  text={
                    msg.content
                      ? msg.content
                          ?.map((content) => {
                            const value = content.text.value;
                            const annotations = content.text.annotations;
                            let annotatedText = value;

                            // Apply annotations if they exist
                            if (annotations) {
                              annotations.forEach((annotation) => {
                                if (annotation.type === 'file_path' && 'file_path' in annotation) {
                                  annotatedText = annotatedText.replaceAll(
                                    annotation.text,
                                    ` ([source](#fileId=${(annotation as any).file_path.file_id})`
                                  );
                                }

                                if (annotation.type === 'file_citation' && annotation.file_citation) {
                                  annotatedText = annotatedText.replaceAll(
                                    annotation.text,
                                    ` ([source](#fileId=${annotation.file_citation.file_id}))`
                                  );
                                }
                              });
                            }
                            return annotatedText;
                          })
                          .join('')
                      : msg.text
                  }
                />
              ))}
              <div ref={messagesEndRef} />
            </Messages>
            <InputForm onSubmit={handleSubmit}>
              <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
                <Input
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder={intl.formatMessage({
                    id: 'ai_input_placeholder',
                    defaultMessage: 'Enter your question',
                  })}
                  rows={1}
                  disabled={inputDisabled || isResponding || isLoading}
                  style={{ paddingRight: '60px' }}
                />
                <Button
                  type='submit'
                  disabled={inputDisabled || isResponding || isLoading}
                  style={{
                    position: 'absolute',
                    right: theme.breakpoints.down('md') ? '8px' : '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '44px',
                    height: '44px',
                    padding: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: inputDisabled || isResponding || isLoading ? 'default' : 'pointer',
                    borderRadius: '10px',
                  }}
                >
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='white'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <line x1='22' y1='2' x2='11' y2='13'></line>
                    <polygon points='22 2 15 22 11 13 2 9 22 2'></polygon>
                  </svg>
                </Button>
              </div>
            </InputForm>
          </ChatContentContainer>
        )}
      </ChatContainer>
    </>
  );
};

export default Chat;
