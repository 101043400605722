import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import type { ObservationSite } from '@soilsense/shared/lib/ObservationSite';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { cropTypeOptions } from '../../consts/cropTypeToPaw';
import {
  useCurrentUser,
  useFarmStore,
  useFarmStore30Days,
  useFarmStore7Days,
  useObservationSiteStore,
} from '../../dataHandlers/RootStore';
import { sensorSettingsFromSiteInfos } from '../Settings/settingsState';
import { showErrorSnackBar, showSuccessSnackBar } from '../SnackBar';

type LocationType = 'field' | 'greenhouse';

const locationTypeOptions = [
  { value: 'field', labelId: 'field' },
  { value: 'greenhouse', labelId: 'greenhouse' },
];

interface CropLocationTypeDialogProps {
  open: boolean;
  onSave: () => void;
  onSkip: () => void;
}

interface SiteConfig {
  id: string;
  name: string;
  cropType?: string;
  locationType?: LocationType;
}

export const CropLocationTypeDialog: React.FC<CropLocationTypeDialogProps> = observer(
  ({ open, onSave, onSkip }) => {
    const intl = useIntl();
    const observationSiteStore = useObservationSiteStore();
    const farmStore = useFarmStore();
    // const farmStoreAI = useFarmStoreAI();
    const farmStore7Days = useFarmStore7Days();
    const farmStore30Days = useFarmStore30Days();
    const sensorSettings = sensorSettingsFromSiteInfos(observationSiteStore.activeSiteDetails);
    const acceptedAiTerms = useCurrentUser()?.acceptedAiTerms;
    const [loading, setLoading] = useState(false);
    const [originalConfigs, setOriginalConfigs] = useState<SiteConfig[]>([]);

    useEffect(() => {
      // emit event when open changes
      if (open) {
        console.log('crop-location-type-dialog-open');
        document.dispatchEvent(new Event('crop-location-type-dialog-open'));
      } else {
        console.log('crop-location-type-dialog-close');
        document.dispatchEvent(new Event('crop-location-type-dialog-close'));
      }
    }, [open]);

    // Initialize siteConfigs with current values from sensorSettings
    const [siteConfigs, setSiteConfigs] = useState<SiteConfig[]>(() => {
      const configs = observationSiteStore.activeSiteDetails.map((site) => ({
        id: site.site.id,
        name: site.site.name || '',
        cropType: sensorSettings[site.site.id]?.cropType || site.site.cropType,
        locationType: sensorSettings[site.site.id]?.locationType || (site.site.locationType as LocationType),
      }));
      setOriginalConfigs(configs);
      return configs;
    });

    useEffect(() => {
      const siteConfigsIds = siteConfigs.map((config) => config.id);
      const activeSiteDetailsIds = observationSiteStore.activeSiteDetails.map((site) => site.site.id);
      if (siteConfigsIds.every((id) => activeSiteDetailsIds.includes(id))) {
        return;
      }

      // update siteConfigs with the new values from the sensorSettings
      setSiteConfigs(
        observationSiteStore.activeSiteDetails.map((site) => ({
          id: site.site.id,
          name: site.site.name || '',
          cropType: sensorSettings[site.site.id]?.cropType || site.site.cropType,
          locationType: sensorSettings[site.site.id]?.locationType || (site.site.locationType as LocationType),
        }))
      );
    }, [observationSiteStore.activeSiteDetails, sensorSettings, siteConfigs]);

    const handleCropTypeChange = (siteId: string, newValue: string) => {
      setSiteConfigs((prev) =>
        prev.map((config) => (config.id === siteId ? { ...config, cropType: newValue } : config))
      );
    };

    const handleLocationTypeChange = (siteId: string, newValue: LocationType) => {
      setSiteConfigs((prev) =>
        prev.map((config) => (config.id === siteId ? { ...config, locationType: newValue } : config))
      );
    };

    const handleSave = async () => {
      setLoading(true);
      const now = Date.now();
      const updates: [string, ObservationSite][] = [];

      // Update all sites with new configurations
      for (const config of siteConfigs) {
        const site = observationSiteStore.activeSiteDetails.find((detail) => detail.site.id === config.id)?.site;

        if (site) {
          updates.push([
            config.id,
            {
              ...site,
              cropType: config.cropType,
              locationType: config.locationType,
            },
          ]);
        }
      }

      try {
        await farmStore.updateObservationSites(updates);
        // await farmStoreAI.updateObservationSites(updates);
        await farmStore7Days.updateObservationSites(updates);
        await farmStore30Days.updateObservationSites(updates);
        onSave();
        setLoading(false);
        showSuccessSnackBar(intl.formatMessage({ id: 'sensor_information_updated_successfully' }));
      } catch (error) {
        showErrorSnackBar(
          intl.formatMessage({ id: 'could_not_update_site_information' }, { error: error as string })
        );
        setLoading(false);
      }
    };

    // Add function to check for changes
    const hasChanges = () => {
      return siteConfigs.some((config) => {
        const original = originalConfigs.find((orig) => orig.id === config.id);
        return original?.cropType !== config.cropType || original?.locationType !== config.locationType;
      });
    };

    if (!acceptedAiTerms) {
      return null;
    }

    return (
      // high z-index to show above the start new chat dialog
      <Dialog
        id='crop-location-type-dialog'
        open={open}
        maxWidth='md'
        fullWidth
        sx={{ overflow: 'visible', zIndex: 1300 }}
      >
        <DialogTitle>{intl.formatMessage({ id: 'crop_location_type_dialog_title' })}</DialogTitle>
        <Divider />
        <DialogContent sx={{ overflow: 'visible', paddingBottom: '100px' }}>
          <Typography paragraph>{intl.formatMessage({ id: 'crop_location_type_dialog_description' })}</Typography>

          <TableContainer component={Paper} sx={{ mt: 2, overflow: 'visible', boxShadow: 0 }}>
            {/* For desktop */}
            <Table sx={{ overflow: 'visible', display: { xs: 'none', md: 'table' } }}>
              <TableHead sx={{ overflow: 'visible' }}>
                <TableRow sx={{ overflow: 'visible' }}>
                  <TableCell>{intl.formatMessage({ id: 'site_name' })}</TableCell>
                  <TableCell width='40%'>{intl.formatMessage({ id: 'crop_type' })}</TableCell>
                  <TableCell width='40%'>{intl.formatMessage({ id: 'location_type' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflow: 'visible' }}>
                {siteConfigs.map((site) => (
                  <TableRow key={site.id} sx={{ overflow: 'visible' }}>
                    <TableCell>{site.name}</TableCell>
                    <TableCell width='35%' sx={{ overflow: 'visible' }}>
                      <FormControl fullWidth size='small' sx={{ overflow: 'visible' }}>
                        <Autocomplete
                          size='small'
                          disablePortal
                          disabled={!!originalConfigs.find((orig) => orig.id === site.id)?.cropType}
                          options={cropTypeOptions
                            .map((option) => ({
                              ...option,
                              label: intl.formatMessage({ id: option.label }),
                            }))
                            .sort((a, b) => {
                              if (b.id.toLowerCase().includes('other')) {
                                return -1;
                              }
                              return a.label.localeCompare(b.label);
                            })}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={cropTypeOptions.find((option) => option.id === site.cropType) || null}
                          onChange={(e, value) => handleCropTypeChange(site.id, value?.id || '')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={intl.formatMessage({ id: 'select_crop_type' })}
                              sx={{
                                '& .MuiInputBase-input::placeholder': {
                                  color: '#666',
                                  opacity: 1,
                                },
                              }}
                            />
                          )}
                          noOptionsText={intl.formatMessage({
                            id: 'crop_type_not_found',
                            defaultMessage:
                              'Crop type not found. Please select "Other" and contact us if you need help.',
                          })}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell width='40%'>
                      <FormControl fullWidth size='small'>
                        <Select
                          value={site.locationType || sensorSettings[site.id]?.locationType || ''}
                          onChange={(e) => handleLocationTypeChange(site.id, e.target.value as LocationType)}
                          disabled={!!originalConfigs.find((orig) => orig.id === site.id)?.locationType}
                          renderValue={(value) =>
                            value && value !== '' ? (
                              intl.formatMessage({ id: value })
                            ) : (
                              <Typography sx={{ color: '#666' }}>
                                {intl.formatMessage({ id: 'select_location_type' })}
                              </Typography>
                            )
                          }
                          displayEmpty
                        >
                          {locationTypeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {intl.formatMessage({ id: option.labelId })}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* For mobile */}
            <Box sx={{ display: { xs: 'block', md: 'none' }, p: 0 }}>
              {siteConfigs.map((site) => (
                <Box key={site.id} sx={{ mb: 3 }}>
                  <Typography variant='subtitle1' sx={{ mb: 1, fontWeight: 'bold' }}>
                    {site.name}
                  </Typography>

                  <FormControl fullWidth size='small' sx={{ mb: 2, overflow: 'visible' }}>
                    <Typography variant='body2' sx={{ mb: 0.5 }}>
                      {intl.formatMessage({ id: 'crop_type' })}
                    </Typography>
                    <Autocomplete
                      size='small'
                      disablePortal
                      disabled={!!originalConfigs.find((orig) => orig.id === site.id)?.cropType}
                      options={cropTypeOptions
                        .map((option) => ({
                          ...option,
                          label: intl.formatMessage({ id: option.label }),
                        }))
                        .sort((a, b) => {
                          if (b.id.toLowerCase().includes('other')) {
                            return -1;
                          }
                          return a.label.localeCompare(b.label);
                        })}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={cropTypeOptions.find((option) => option.id === site.cropType) || null}
                      onChange={(e, value) => handleCropTypeChange(site.id, value?.id || '')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({ id: 'select_crop_type' })}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              color: '#666',
                              opacity: 1,
                            },
                          }}
                        />
                      )}
                      noOptionsText={intl.formatMessage({
                        id: 'crop_type_not_found',
                        defaultMessage:
                          'Crop type not found. Please select "Other" and contact us if you need help.',
                      })}
                    />
                  </FormControl>

                  <FormControl fullWidth size='small'>
                    <Typography variant='body2' sx={{ mb: 0.5 }}>
                      {intl.formatMessage({ id: 'location_type' })}
                    </Typography>
                    <Select
                      value={site.locationType || sensorSettings[site.id]?.locationType || ''}
                      onChange={(e) => handleLocationTypeChange(site.id, e.target.value as LocationType)}
                      disabled={!!originalConfigs.find((orig) => orig.id === site.id)?.locationType}
                      renderValue={(value) =>
                        value && value !== '' ? (
                          intl.formatMessage({ id: value })
                        ) : (
                          <Typography sx={{ color: '#666' }}>
                            {intl.formatMessage({ id: 'select_location_type' })}
                          </Typography>
                        )
                      }
                      displayEmpty
                    >
                      {locationTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {intl.formatMessage({ id: option.labelId })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Divider sx={{ mt: 2 }} />
                </Box>
              ))}
            </Box>
          </TableContainer>
        </DialogContent>
        <DialogActions
          sx={{
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
            p: 2,
          }}
        >
          <Button onClick={onSkip}>{intl.formatMessage({ id: 'skip' })}</Button>
          <Button variant='contained' color='primary' onClick={handleSave} disabled={!hasChanges() || loading}>
            {loading ? <CircularProgress size={20} /> : intl.formatMessage({ id: 'save' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
