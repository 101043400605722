import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCurrentUser, useFarmStore30Days, useFarmStore7Days } from '../../../dataHandlers/RootStore';
import { BASE_URL } from '../../../utils/consts';
import type { AIState, AIStateActions, HistoricThreadMessage, Thread, ToolStatus } from '../hooks/useAIState';
import { useFetchWithAuth } from '../utils/fetchWithAuth';

type AIStateContextType = {
  state: AIState;
  actions: AIStateActions;
};

// Create context with a default value
const AIStateContext = createContext<AIStateContextType | undefined>(undefined);

// Provider component
export const AIStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentUser = useCurrentUser();
  const [generalChat, setGeneralChat] = useState<boolean | null>(null);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedHistoricThreadId, setSelectedHistoricThreadId] = useState<string | null>(null);
  const [historicThreadMessages, setHistoricThreadMessages] = useState<HistoricThreadMessage[] | null>(null);
  const [loadingHistoricThread, setLoadingHistoricThread] = useState(false);
  const [chatKey, setChatKey] = useState(0);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [cropType, setCropType] = useState<string | null>(null);
  const [locationType, setLocationType] = useState<string | null>(null);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [existsThreadInLast5Minutes, setExistsThreadInLast5Minutes] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [allThreadsLoading, setAllThreadsLoading] = useState(false);
  const [period, setPeriod] = useState<'7days' | '30days' | null>(null);
  const [toolStatus, setToolStatus] = useState<ToolStatus>({ performingAction: false, activeTools: [] });
  const [toolStatusHistory, setToolStatusHistory] = useState<Array<{ time: Date; status: ToolStatus }>>([]);

  // Only use fetchWithAuth when we have a current user
  const fetchWithAuth = useFetchWithAuth();
  const farmStore7Days = useFarmStore7Days();
  const farmStore30Days = useFarmStore30Days();

  const aiFarmStore = useMemo(() => {
    return period === '7days' ? farmStore7Days : farmStore30Days;
  }, [period, farmStore7Days, farmStore30Days]);

  // set generalChat state based on the type of thread that is selected in threadId
  useEffect(() => {
    if (threadId && threads.length > 0) {
      const thread = threads.find((thread) => thread.threadId === threadId);
      if (thread) {
        setGeneralChat(thread.chatType === 'general');
        return;
      }
    }
    if (selectedHistoricThreadId && threads.length > 0) {
      const thread = threads.find((thread) => thread.threadId === selectedHistoricThreadId);
      if (thread) {
        setGeneralChat(thread.chatType === 'general');
        return;
      }
    }
  }, [threadId, threads, selectedHistoricThreadId]);

  // Add a new effect to log toolStatus changes for debugging
  useEffect(() => {
    console.log('Tool status updated:', toolStatus);

    // Add to history
    setToolStatusHistory((prev) => {
      const newHistory = [...prev, { time: new Date(), status: { ...toolStatus } }];
      // Keep only the last 10 entries
      if (newHistory.length > 10) {
        return newHistory.slice(-10);
      }
      return newHistory;
    });
  }, [toolStatus]);

  const fetchAllThreads = useCallback(async () => {
    if (!currentUser) return; // Skip if no user is logged in

    setAllThreadsLoading(true);
    try {
      const response = await fetchWithAuth(`${BASE_URL}/llm/threads`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const threadsData = await response.json();
      if (threadsData.error) {
        throw new Error(JSON.stringify(threadsData.error));
      }
      if (!threadsData.threads) {
        throw new Error('Failed to fetch threads');
      }
      setThreads(threadsData.threads as Thread[]);
      // if thread is from the last 5 minutes, set it as the selected thread
      const lastFiveMinutes = new Date(Date.now() - 10 * 60 * 1000);
      const lastFiveMinutesThread = threadsData.threads.find(
        (thread: Thread) => new Date(thread.createdAt) > lastFiveMinutes
      );
      if (lastFiveMinutesThread) {
        setThreadId(lastFiveMinutesThread.threadId as string);
        setExistsThreadInLast5Minutes(true);
      }
      setAllThreadsLoading(false);
    } catch (error) {
      console.error('Error fetching threads', error);
      setAllThreadsLoading(false);
    } finally {
      setAllThreadsLoading(false);
    }
  }, [fetchWithAuth, currentUser]);

  useEffect(() => {
    // Only initialize when we have a user
    if (!initialized && currentUser) {
      setInitialLoading(true);
      fetchAllThreads();
      setInitialized(true);
      setInitialLoading(false);
    }
  }, [fetchAllThreads, initialized, currentUser]);

  const getAndSetSummaryForThread = useCallback(
    async (threadId: string, content: string) => {
      if (!currentUser) return; // Skip if no user is logged in

      const thread = threads.find((thread) => thread.threadId === threadId);
      if (thread?.summary) {
        console.log('Summary already found for thread', threadId);
        return;
      }
      try {
        console.log('Sending first message to a general chat thread with summarization');
        const response = await fetchWithAuth(`${BASE_URL}/llm/threads/generalChat/${threadId}/firstMessage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content,
          }),
        });
        const summary = await response.json();
        console.log({ summary });
        if (summary.error || !summary.ok) {
          throw new Error(JSON.stringify(summary.error || 'Failed to get summary'));
        }
        console.log({ summary });
        // set summary on the thread
        setThreads((prevThreads) =>
          prevThreads.map((thread) =>
            thread.threadId === threadId ? { ...thread, summary: summary.summary } : thread
          )
        );
      } catch (error) {
        console.error('Error getting and setting summary for thread', error);
      }
    },
    [fetchWithAuth, threads, currentUser]
  );

  // Enhance the setToolStatus action to ensure currentAction is properly handled
  const enhancedSetToolStatus = useCallback((status: ToolStatus) => {
    console.log('Setting tool status:', status);
    setToolStatus(status);
  }, []);

  // Add a method to add a tool to the active tools list
  const addActiveTool = useCallback((toolId: string) => {
    setToolStatus((prev) => {
      // Add the tool if it's not already in the list
      if (!prev.activeTools.includes(toolId)) {
        return {
          performingAction: true,
          activeTools: [...prev.activeTools, toolId],
        };
      }
      return prev;
    });
  }, []);

  // Add a method to remove a tool from the active tools list
  const removeActiveTool = useCallback((toolId: string) => {
    setToolStatus((prev) => {
      const updatedTools = prev.activeTools.filter((id) => id !== toolId);
      return {
        performingAction: updatedTools.length > 0,
        activeTools: updatedTools,
      };
    });
  }, []);

  // Add a method to clear all active tools
  const clearActiveTools = useCallback(() => {
    setToolStatus({ performingAction: false, activeTools: [] });
  }, []);

  // Wrap all state variables into a single AIState object
  const state: AIState = useMemo(
    () => ({
      generalChat,
      selectedSiteId,
      selectedHistoricThreadId,
      historicThreadMessages,
      loadingHistoricThread,
      chatKey,
      threads,
      cropType,
      locationType,
      threadId,
      existsThreadInLast5Minutes,
      initialLoading,
      allThreadsLoading,
      period,
      aiFarmStore,
      toolStatus,
    }),
    [
      generalChat,
      selectedSiteId,
      selectedHistoricThreadId,
      historicThreadMessages,
      loadingHistoricThread,
      chatKey,
      threads,
      cropType,
      locationType,
      threadId,
      existsThreadInLast5Minutes,
      initialLoading,
      allThreadsLoading,
      period,
      aiFarmStore,
      toolStatus,
    ]
  );

  // Wrap all actions into a single AIStateActions object
  const actions: AIStateActions = useMemo(
    () => ({
      setGeneralChat,
      setSelectedSiteId,
      setSelectedHistoricThreadId: (id: string | null) => {
        setSelectedHistoricThreadId(id);
        setHistoricThreadMessages(null);
      },
      setHistoricThreadMessages,
      setLoadingHistoricThread,
      setChatKey,
      setThreads,
      setCropType,
      setLocationType,
      fetchAllThreads,
      setThreadId,
      setPeriod,
      getAndSetSummaryForThread,
      setToolStatus: enhancedSetToolStatus,
      addActiveTool,
      removeActiveTool,
      clearActiveTools,
    }),
    [
      fetchAllThreads,
      getAndSetSummaryForThread,
      enhancedSetToolStatus,
      addActiveTool,
      removeActiveTool,
      clearActiveTools,
    ]
  );

  // Create a memoized context value
  const contextValue = useMemo(
    () => ({
      state,
      actions,
    }),
    [state, actions]
  );

  // Don't render children until user is available
  if (!currentUser) {
    return null; // Or a loading state if preferred
  }

  return <AIStateContext.Provider value={contextValue}>{children}</AIStateContext.Provider>;
};

// Custom hook to use the AI State context
export const useAIStateContext = () => {
  const context = useContext(AIStateContext);
  if (context === undefined) {
    throw new Error('useAIStateContext must be used within an AIStateProvider');
  }
  return [context.state, context.actions] as const;
};
