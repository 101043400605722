import type { DataLoggerConfiguration, ValueHistory } from '@soilsense/shared';
import { getTransformFunction } from '@soilsense/shared';
import { toJS } from 'mobx';
import type { IValidatedDateRangeObj } from '../components/RangeDatePicker';
import {
  BOT_SENSOR_ATTRIBUTE,
  BOX_SENSOR_ATTRIBUTE,
  MID_BOT_SENSOR_ATTRIBUTE,
  MID_SENSOR_ATTRIBUTE,
  TOP_SENSOR_ATTRIBUTE,
} from '../consts/backendAttributes';
import type { IDataElement } from '../interfaces/IDataElement';
import type { IrrigationStatus } from '../utils/getMarkerColorFromReadings';
import { getIrrigationStatusFromReadings } from '../utils/getMarkerColorFromReadings';
import type { ObservationSiteWithId } from './ObservationSiteStore';
import type { PrecipitationBuckets } from './Precipitation';
import type {
  BOX_TEMPERATURE,
  PLANT_AVAILABLE_WATER_BOT,
  PLANT_AVAILABLE_WATER_MID,
  PLANT_AVAILABLE_WATER_MID_BOT,
  PLANT_AVAILABLE_WATER_TOP,
  SALINITY_BOT,
  SALINITY_MID,
  SALINITY_MID_BOT,
  SALINITY_TOP,
  TEMPERATURE_BOT,
  TEMPERATURE_MID,
  TEMPERATURE_MID_BOT,
  TEMPERATURE_TOP,
  VOLUMETRIC_WATER_CONTENT_BOT,
  VOLUMETRIC_WATER_CONTENT_MID,
  VOLUMETRIC_WATER_CONTENT_MID_BOT,
  VOLUMETRIC_WATER_CONTENT_TOP,
} from './utils/constsAndTypes';
import { MOISTURE_SAFE_RANGE, SALINITY_SAFE_RANGE } from './utils/constsAndTypes';
import type { ExtractedValuesPaw, ITransformFunctionsObj } from './utils/extractValues';
import extractValuesPaw, { DEFAULT_TRANSFORM_FUNCTION, extractValuesMmLinearAverage } from './utils/extractValues';

export type TransformedData = ExtractedValuesPaw & {
  timestamp: number;
  [MOISTURE_SAFE_RANGE]: readonly [number, number] | undefined;
  [SALINITY_SAFE_RANGE]: readonly [number, number] | undefined;
  currentWaterContentMm: number | undefined;
  fieldCapacityMm: number | undefined;
  additionalCapacityMm: number | undefined;
};

export type NameMap = Readonly<{
  [VOLUMETRIC_WATER_CONTENT_TOP]: string;
  [VOLUMETRIC_WATER_CONTENT_MID]: string;
  [VOLUMETRIC_WATER_CONTENT_MID_BOT]: string;
  [VOLUMETRIC_WATER_CONTENT_BOT]: string;
  [PLANT_AVAILABLE_WATER_TOP]: string;
  [PLANT_AVAILABLE_WATER_MID]: string;
  [PLANT_AVAILABLE_WATER_MID_BOT]: string;
  [PLANT_AVAILABLE_WATER_BOT]: string;
  [SALINITY_TOP]: string;
  [SALINITY_MID]: string;
  [SALINITY_MID_BOT]: string;
  [SALINITY_BOT]: string;
  [TEMPERATURE_TOP]: string;
  [TEMPERATURE_MID]: string;
  [TEMPERATURE_MID_BOT]: string;
  [TEMPERATURE_BOT]: string;
  [BOX_TEMPERATURE]: string;
}>;

export type IMinMax = Readonly<{
  vwc: Readonly<{
    min: number;
    max: number;
  }>;
  paw: Readonly<{
    min: number;
    max: number;
  }>;
}>;

export type IrrigationStatusBox = Readonly<{
  timeRange: readonly [number, number];
  status: IrrigationStatus;
}>;

export type SingleSensorData = Readonly<{
  rainfall: PrecipitationBuckets;
  irrigation: PrecipitationBuckets;
  data: readonly TransformedData[];
  minMax: IMinMax;
  statusBoxes: readonly IrrigationStatusBox[];
  containsMoisture: boolean;
  containsSalinity: boolean;
  containsBoxTemperature: boolean;
}>;

const SIX_HOURS = 6 * 60 * 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;

type Transform = Readonly<{
  configuration: DataLoggerConfiguration;
  functions: ITransformFunctionsObj;
  fcs:
    | Readonly<{
        top: number | undefined;
        bot: number | undefined;
      }>
    | undefined;
  depths: {
    top: number;
    bot: number;
  };
}>;

export function getTransformedData(
  dateRange: IValidatedDateRangeObj,
  site: ObservationSiteWithId,
  configuration: DataLoggerConfiguration,
  sensorData: readonly IDataElement[],
  amendSalinity: boolean,
  showHistoricalCalibrations: boolean
): Readonly<{
  data: readonly TransformedData[];
  minMax: IMinMax;
  statusBoxes: readonly IrrigationStatusBox[];
}> {
  console.time(`${site.id}`);
  const moistureSafeRange = toJS(site.safeRanges.plantAvailableWater);
  const salinitySafeRange = toJS(site.safeRanges.salinity);

  const transformHistory: ValueHistory<Transform | undefined> = showHistoricalCalibrations
    ? site.soilDataSourceHistory.map(({ startTimestamp, value }) => {
        const configuration = value?.configuration;
        if (configuration == undefined) {
          return { startTimestamp, value: undefined };
        }
        return {
          startTimestamp,
          value: {
            configuration,
            functions: getTransformFunctions(configuration),
            fcs: {
              top: configuration.cableTop.calibration?.fieldCapacity,
              bot: configuration.cableBottom.calibration?.fieldCapacity,
            },
            depths: {
              top: configuration.cableTop.depth,
              bot: configuration.cableBottom.depth,
            },
          },
        };
      })
    : [
        {
          startTimestamp: 0,
          value: {
            configuration,
            functions: getTransformFunctions(configuration),
            fcs: {
              top: configuration.cableTop.calibration?.fieldCapacity,
              bot: configuration.cableBottom.calibration?.fieldCapacity,
            },
            depths: {
              top: configuration.cableTop.depth,
              bot: configuration.cableBottom.depth,
            },
          },
        },
      ];

  const transformedData: TransformedData[] = [];
  let minVwc = Infinity;
  let maxVwc = -Infinity;
  let minPaw = Infinity;
  let maxPaw = -Infinity;

  let transformIndex = 0;
  for (const el of sensorData) {
    const { timestamp, data } = el;

    while (
      transformIndex < transformHistory.length - 1 &&
      timestamp >= transformHistory[transformIndex + 1].startTimestamp
    ) {
      transformIndex++;
    }

    const transform = transformHistory[transformIndex];
    if (transform.value == undefined) {
      continue;
    }

    const {
      configuration: { topAndBottomCablesSwapped },
      functions,
    } = transform.value;

    // A mistake in the field can cause sensors to be swapped
    const top = data[topAndBottomCablesSwapped ? BOT_SENSOR_ATTRIBUTE : TOP_SENSOR_ATTRIBUTE];
    const bot = data[topAndBottomCablesSwapped ? TOP_SENSOR_ATTRIBUTE : BOT_SENSOR_ATTRIBUTE];
    const mid = data[MID_SENSOR_ATTRIBUTE];
    const midBot = data[MID_BOT_SENSOR_ATTRIBUTE];
    const box = data[BOX_SENSOR_ATTRIBUTE];

    const vals = extractValuesPaw(top, bot, box, functions, mid, midBot, amendSalinity);
    const mmVals = extractValuesMmLinearAverage({
      top: { fc: transform.value?.fcs?.top, vwc: vals.vwcTop, depthMm: transform.value.depths.top * 100 },
      bot: { fc: transform.value?.fcs?.bot, vwc: vals.vwcBot, depthMm: transform.value.depths.bot * 100 },
      depthOfInterestMm: transform.value.depths.bot * 100 + 100,
    });

    transformedData.push({
      ...vals,
      ...mmVals,
      timestamp,
      [MOISTURE_SAFE_RANGE]: moistureSafeRange,
      [SALINITY_SAFE_RANGE]: salinitySafeRange,
    });

    minVwc = Math.min(minVwc, vals.vwcBot ?? minVwc, vals.vwcMid ?? minVwc, vals.vwcTop ?? minVwc);
    maxVwc = Math.max(maxVwc, vals.vwcBot ?? maxVwc, vals.vwcMid ?? maxVwc, vals.vwcTop ?? maxVwc);
    minPaw = Math.min(minPaw, vals.pawBot ?? minPaw, vals.pawMid ?? minPaw, vals.pawTop ?? minPaw);
    maxPaw = Math.max(maxPaw, vals.pawBot ?? maxPaw, vals.pawMid ?? maxPaw, vals.pawTop ?? maxPaw);
  }

  const minMax = {
    vwc: {
      min: Math.floor(minVwc * 0.95),
      max: Math.ceil(maxVwc * 1.05),
    },
    paw: {
      min: Math.floor(Math.max(minPaw, 0) * 0.95),
      max: Math.ceil(maxPaw * 1.05),
    },
  };
  const statusBoxes = calculateIrrigationStatusBoxes(dateRange, transformedData);
  console.timeEnd(`${site.id}`);
  return { minMax, data: transformedData, statusBoxes };
}

export function getTransformFunctions(configuration: DataLoggerConfiguration): ITransformFunctionsObj {
  const { cableTop, cableMiddle, cableMiddleBottom, cableBottom } = configuration;

  const fieldCapacityTop = cableTop.calibration?.fieldCapacity;
  const fieldCapacityMid = cableMiddle?.calibration?.fieldCapacity;
  const fieldCapacityMidBot = cableMiddleBottom?.calibration?.fieldCapacity;
  const fieldCapacityBot = cableBottom.calibration?.fieldCapacity;
  const wiltingPointTop = cableTop?.calibration?.wiltingPoint;
  const wiltingPointMid =
    cableMiddle?.calibration?.wiltingPoint ??
    (fieldCapacityMid != undefined && fieldCapacityTop != undefined && wiltingPointTop != undefined
      ? (fieldCapacityMid * wiltingPointTop) / fieldCapacityTop
      : undefined);
  const wiltingPointBot =
    cableBottom.calibration?.wiltingPoint ??
    (fieldCapacityBot != undefined && fieldCapacityTop != undefined && wiltingPointTop != undefined
      ? (fieldCapacityBot * wiltingPointTop) / fieldCapacityTop
      : undefined);
  const wiltingPointMidBot =
    cableMiddleBottom?.calibration?.wiltingPoint ??
    (fieldCapacityMidBot != undefined && fieldCapacityMid != undefined && wiltingPointMid != undefined
      ? (fieldCapacityMidBot * wiltingPointMid) / fieldCapacityMid
      : undefined);

  return {
    transformTop: getTransformFunction(fieldCapacityTop, wiltingPointTop) ?? DEFAULT_TRANSFORM_FUNCTION,
    transformMid: getTransformFunction(fieldCapacityMid, wiltingPointMid) ?? DEFAULT_TRANSFORM_FUNCTION,
    transformMidBot: getTransformFunction(fieldCapacityMidBot, wiltingPointMidBot) ?? DEFAULT_TRANSFORM_FUNCTION,
    transformBot: getTransformFunction(fieldCapacityBot, wiltingPointBot) ?? DEFAULT_TRANSFORM_FUNCTION,
  };
}

export function dataContainsMoisture(data: readonly IDataElement[]): boolean {
  return data.some(
    ({ data }) =>
      data.top.water_vol != undefined || data.mid?.water_vol != undefined || data.bot.water_vol != undefined
  );
}

export function dataContainsSalinity(data: readonly IDataElement[]): boolean {
  return data.some(
    ({ data }) =>
      data.top.salinity_porewater != undefined ||
      data.mid?.salinity_porewater != undefined ||
      data.bot.salinity_porewater != undefined
  );
}

export function dataContainsBoxTemperature(data: readonly IDataElement[]): boolean {
  return data.some(({ data }) => data.box.box_temperature != undefined);
}

export function calculateIrrigationStatusBoxes(
  timeRange: IValidatedDateRangeObj,
  data: readonly TransformedData[]
): readonly IrrigationStatusBox[] {
  const result: IrrigationStatusBox[] = [];
  const startTimestamp = timeRange.startDate.unix() * 1000;
  const endTimestamp = timeRange.endDate.unix() * 1000;

  let lastBox: IrrigationStatusBox | undefined;

  for (const each of data) {
    const status = getIrrigationStatusFromReadings(
      { bot: each.pawBot, mid: each.pawMid, top: each.pawTop },
      each[MOISTURE_SAFE_RANGE]
    );
    if (status == lastBox?.status) {
      if (each.timestamp - lastBox.timeRange[1] > ONE_DAY) {
        result.push(lastBox);
        result.push({
          timeRange: [lastBox.timeRange[1], each.timestamp],
          status: 'unknown',
        });
        lastBox = { timeRange: [each.timestamp, each.timestamp], status };
      } else {
        lastBox = {
          timeRange: [lastBox.timeRange[0], each.timestamp],
          status,
        };
      }
    } else {
      if (lastBox == undefined) {
        lastBox = { timeRange: [each.timestamp, each.timestamp], status };
      } else {
        result.push(lastBox);
        if (each.timestamp - lastBox.timeRange[1] > SIX_HOURS) {
          result.push({
            timeRange: [lastBox.timeRange[1], each.timestamp],
            status: 'unknown',
          });
          lastBox = { timeRange: [each.timestamp, each.timestamp], status };
        } else {
          lastBox = { timeRange: [lastBox.timeRange[1], each.timestamp], status };
        }
      }
    }
  }

  if (lastBox != undefined) {
    result.push(lastBox);
  }

  if (result.length == 0) {
    result.push({
      timeRange: [startTimestamp, endTimestamp],
      status: 'unknown',
    });
  } else {
    const firstTimeRange = result[0].timeRange;
    if (firstTimeRange[0] - startTimestamp < SIX_HOURS || result[0].status == 'unknown') {
      result[0] = {
        ...result[0],
        timeRange: [startTimestamp, firstTimeRange[1]],
      };
    } else {
      result.splice(0, 0, {
        timeRange: [startTimestamp, firstTimeRange[0]],
        status: 'unknown',
      });
    }

    const lastIndex = result.length - 1;
    const lastTimeRange = result[lastIndex].timeRange;
    if (endTimestamp - lastTimeRange[1] < SIX_HOURS || result[lastIndex].status == 'unknown') {
      result[lastIndex] = {
        ...result[lastIndex],
        timeRange: [lastTimeRange[0], endTimestamp],
      };
    } else {
      result.push({
        timeRange: [lastTimeRange[1], endTimestamp],
        status: 'unknown',
      });
    }
  }

  return result;
}
