import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH } from '@soilsense/shared';
import type { Dispatch } from 'react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberField from '../NumberField';

export const DEFAULT_DEPTHS_BY_SENSOR_COUNT: Record<
  2 | 3 | 4,
  Readonly<{
    cableTop: number;
    cableMiddle: number | undefined;
    cableMiddleBottom: number | undefined;
    cableBottom: number;
  }>
> = {
  2: {
    cableTop: DEFAULT_TOP_DEPTH,
    cableMiddle: undefined,
    cableMiddleBottom: undefined,
    cableBottom: DEFAULT_BOT_DEPTH,
  },
  3: {
    cableTop: DEFAULT_TOP_DEPTH,
    cableMiddle: 25,
    cableMiddleBottom: undefined,
    cableBottom: DEFAULT_BOT_DEPTH,
  },
  4: {
    cableTop: DEFAULT_TOP_DEPTH,
    cableMiddle: 20,
    cableMiddleBottom: 30,
    cableBottom: DEFAULT_BOT_DEPTH,
  },
};

export const SensorDepthInput: React.FC<{
  label: string;
  depth: number | undefined;
  setDepth: Dispatch<number | undefined>;
  fullWidth?: boolean;
  disabled?: boolean;
}> = ({ label, depth, setDepth, fullWidth, disabled }) => {
  const intl = useIntl();
  return (
    <NumberField
      label={label}
      value={depth}
      setValue={setDepth}
      min={0}
      integer
      disabled={disabled}
      InputProps={{
        endAdornment: <InputAdornment position='end'> cm</InputAdornment>,
      }}
      fullWidth={fullWidth}
      hint={intl.formatMessage({ id: 'sensor_depth_hint' })}
    />
  );
};

export type MidDepthValue = number | undefined | 'disabled';

type Props = Readonly<{
  topDepth: number | undefined;
  setTopDepth: Dispatch<number | undefined>;
  midDepth: MidDepthValue;
  setMidDepth: Dispatch<MidDepthValue>;
  midBotDepth: MidDepthValue;
  setMidBotDepth: Dispatch<MidDepthValue>;
  botDepth: number | undefined;
  setBotDepth: Dispatch<number | undefined>;
  fullWidth?: boolean;
  disabled?: boolean;
}>;

export const SensorDepths: React.FC<Props> = ({
  topDepth,
  setTopDepth,
  midDepth,
  setMidDepth,
  midBotDepth,
  setMidBotDepth,
  botDepth,
  setBotDepth,
  fullWidth,
  disabled,
}) => {
  const intl = useIntl();
  const selectLabel = intl.formatMessage({ id: 'number_of_sensors' });
  return (
    <>
      <FormControl variant='outlined' fullWidth={fullWidth}>
        <InputLabel id='number-of-sensors-selector-label'>{selectLabel}</InputLabel>
        <Select
          labelId='number-of-sensors-selector-label'
          label={selectLabel}
          value={midDepth == 'disabled' ? 2 : midBotDepth == 'disabled' ? 3 : 4}
          onChange={(e) => {
            const sensorCount = Number(e.target.value) as 2 | 3 | 4;
            const defaultMidDepth = DEFAULT_DEPTHS_BY_SENSOR_COUNT[sensorCount].cableMiddle;
            const defaultMidBotDepth = DEFAULT_DEPTHS_BY_SENSOR_COUNT[sensorCount].cableMiddleBottom;
            setMidDepth(defaultMidDepth ?? 'disabled');
            setMidBotDepth(defaultMidBotDepth ?? 'disabled');
          }}
          disabled={disabled}
        >
          <MenuItem value={2}>
            <FormattedMessage id='sensors_plural' values={{ count: 2 }} />
          </MenuItem>
          <MenuItem value={3}>
            <FormattedMessage id='sensors_plural' values={{ count: 3 }} />
          </MenuItem>
          <MenuItem value={4}>
            <FormattedMessage id='sensors_plural' values={{ count: 4 }} />
          </MenuItem>
        </Select>
      </FormControl>
      <SensorDepthInput
        label={intl.formatMessage({ id: 'top_sensor_depth' })}
        depth={topDepth}
        setDepth={setTopDepth}
        fullWidth={fullWidth}
        disabled={disabled}
      />
      {midDepth != 'disabled' && (
        <SensorDepthInput
          label={intl.formatMessage({ id: 'middle_sensor_depth' })}
          depth={midDepth}
          setDepth={setMidDepth}
          fullWidth={fullWidth}
          disabled={disabled}
        />
      )}
      {midBotDepth != 'disabled' && (
        <SensorDepthInput
          label={intl.formatMessage({ id: 'middle_bottom_sensor_depth' })}
          depth={midBotDepth}
          setDepth={setMidBotDepth}
          fullWidth={fullWidth}
          disabled={disabled}
        />
      )}
      <SensorDepthInput
        label={intl.formatMessage({ id: 'bottom_sensor_depth' })}
        depth={botDepth}
        setDepth={setBotDepth}
        fullWidth={fullWidth}
        disabled={disabled}
      />
    </>
  );
};
