import type { Coordinates } from '@soilsense/shared';
import type { NameMap, TransformedData } from 'dataHandlers/SensorTransformer';
import { SALINITY_SAFE_RANGE } from 'dataHandlers/utils/constsAndTypes';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { translateDethNames } from '../../../i18n/utils';
import { getChartColors } from './Config';
import type { ChartZoom, DatasetSpec, SafeRangeOptions, TimeSeriesSpec } from './TimeSeriesChart';
import { TimeSeriesChart } from './TimeSeriesChart';
import { calculateNightTimeAnnotations } from './utils/calculateNightTimeAnnotations';

type Props = {
  data: readonly TransformedData[];
  chartZoom: ChartZoom;
  nameMap: NameMap;
  useSingleChart: boolean;
  coordinates: Coordinates;
};

export function ChartJsSalinityChart({
  data,
  chartZoom,
  nameMap,
  useSingleChart,
  coordinates,
}: Props): JSX.Element {
  const intl = useIntl();
  const Y_LABEL = intl.formatMessage({ id: 'controls_ec_25_c_reference' });
  const timeSeriesSpec = useMemo((): TimeSeriesSpec => {
    const { salinityColor, safeRangeColor, safeRangeBackgroundColor } = getChartColors();
    const labels: number[] = [];
    const topSpec: DatasetSpec = {
      label: translateDethNames(nameMap.salTop, intl),
      data: [],
      color: salinityColor.cableTop,
    };
    const midSpec: DatasetSpec = {
      label: translateDethNames(nameMap.salMid, intl),
      data: [],
      color: salinityColor.cableMiddle,
    };
    const midBotSpec: DatasetSpec = {
      label: translateDethNames(nameMap.salMidBot, intl),
      data: [],
      color: salinityColor.cableMiddleBottom,
    };
    const botSpec: DatasetSpec = {
      label: translateDethNames(nameMap.salBot, intl),
      data: [],
      color: salinityColor.cableBottom,
    };
    let topContainsSalinity = false;
    let midContainsSalinity = false;
    let midBotContainsSalinity = false;
    let botContainsSalinity = false;
    for (const each of data) {
      labels.push(each.timestamp);
      topContainsSalinity = topContainsSalinity || each.salTop != undefined;
      midContainsSalinity = midContainsSalinity || each.salMid != undefined;
      midBotContainsSalinity = midBotContainsSalinity || each.salMidBot != undefined;
      botContainsSalinity = botContainsSalinity || each.salBot != undefined;
      topSpec.data.push(each.salTop ?? null);
      midSpec.data.push(each.salMid ?? null);
      midBotSpec.data.push(each.salMidBot ?? null);
      botSpec.data.push(each.salBot ?? null);
    }
    const datasetSpecs: DatasetSpec[] = [];
    if (topContainsSalinity) {
      datasetSpecs.push(topSpec);
    }
    if (midContainsSalinity) {
      datasetSpecs.push(midSpec);
    }
    if (midBotContainsSalinity) {
      datasetSpecs.push(midBotSpec);
    }
    if (botContainsSalinity) {
      datasetSpecs.push(botSpec);
    }

    const safeRangeBottom = data[0]?.[SALINITY_SAFE_RANGE]?.[0];
    const safeRangeTop = data[0]?.[SALINITY_SAFE_RANGE]?.[1];
    const safeRangeOptions: SafeRangeOptions | undefined =
      safeRangeBottom != null && safeRangeTop != null
        ? {
            range: [safeRangeBottom, safeRangeTop],
            label: intl.formatMessage({ id: 'salinity_safe_range', defaultMessage: SALINITY_SAFE_RANGE }),
            borderColor: safeRangeColor,
            backgroundColor: safeRangeBackgroundColor,
          }
        : undefined;

    const firstTimestamp = moment(labels[0]);
    const lastTimestamp = moment(labels[labels.length - 1]);
    const timeUnit = lastTimestamp.diff(firstTimestamp, 'day') <= 3 ? 'hour' : 'day';

    const [nightTimeAnnotations] = calculateNightTimeAnnotations(
      moment(chartZoom.bounds?.[0] ?? firstTimestamp),
      moment(chartZoom.bounds?.[1] ?? lastTimestamp),
      coordinates
    );

    return {
      labels,
      chartZoom,
      datasetSpecs,
      rightAxisSpec: undefined,
      safeRangeOptions,
      timeUnit,
      annotations: nightTimeAnnotations,
      aggregates: [],
    };
  }, [chartZoom, data, nameMap.salBot, nameMap.salMid, nameMap.salMidBot, nameMap.salTop, intl, coordinates]);

  if (useSingleChart) {
    return <TimeSeriesChart {...timeSeriesSpec} yLabel={Y_LABEL} yMin={undefined} yMax={undefined} yUnit='dS/m' />;
  } else {
    return (
      <>
        {timeSeriesSpec.datasetSpecs.map((datasetSpec, index) => (
          <TimeSeriesChart
            {...timeSeriesSpec}
            key={index}
            datasetSpecs={[datasetSpec]}
            yLabel={Y_LABEL}
            yMin={undefined}
            yMax={undefined}
            yUnit='dS/m'
            small
          />
        ))}
      </>
    );
  }
}
