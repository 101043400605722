import { ArrowDownward, Person } from '@mui/icons-material';
import { keyframes, styled } from '@mui/material/styles';
import { RobotHappy } from 'mdi-material-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Markdown from 'react-markdown';

type MessagesProps = {
  children: React.ReactNode;
  onIsAtBottomChange?: (isAtBottom: boolean) => void;
  style?: React.CSSProperties;
};

const ScrollButton = styled('button')({
  position: 'sticky',
  bottom: '0px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  color: '#000',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0.9,
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  zIndex: 1,
  margin: '0 auto',
  '&:hover': {
    opacity: 1,
  },
  padding: 8,
});

const MessagesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  overflowY: 'auto',
  flexGrow: 1,
  minHeight: 0, // Important for proper flexbox behavior
  maxHeight: '100%', // Constrain to parent height
});

const MessageBase = styled('div')({
  margin: '8px 0',
  padding: '8px 16px',
  borderRadius: '15px',
  maxWidth: '80%',
  overflowWrap: 'break-word',
  position: 'relative',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
    padding: '6px 12px',
    maxWidth: '90%',
  },
});

const IconWrapper = styled('div')({
  position: 'absolute',
  width: '24px',
  height: '24px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

export const UserMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-end',
  color: '#fff',
  backgroundColor: '#000',
  '& .icon-wrapper': {
    top: '-12px',
    right: '-12px',
    '& svg': {
      color: '#000',
    },
  },
});

export const AssistantMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-start',
  backgroundColor: '#efefef',
  '& .icon-wrapper': {
    top: '-12px',
    left: '-12px',
  },
  '& img': {
    maxWidth: '100%',
    margin: '8px 0',
    borderRadius: '8px',
  },
});

export const CodeMessageStyled = styled(MessageBase)({
  alignSelf: 'flex-start',
  backgroundColor: '#e9e9e9',
  fontFamily: 'monospace',
  padding: '10px 16px',
  '& > div': {
    marginTop: '4px',
  },
  '& span': {
    color: '#b8b8b8',
    marginRight: '8px',
  },
});

const dotAnimation = keyframes`
  0%, 20% { content: ''; }
  30% { content: '.'; }
  50% { content: '..'; }
  70% { content: '...'; }
  100% { content: ''; }
`;

const ThinkingMessage = styled(MessageBase)({
  alignSelf: 'flex-start', // Ensure it's aligned to the left like other assistant messages
  backgroundColor: '#efefef',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '& .icon-wrapper': {
    top: '-12px',
    left: '-12px',
  },
});

const ThinkingText = styled('div')({
  fontWeight: 500,
  '&::after': {
    content: "'.'",
    animation: `${dotAnimation} 1.5s infinite`,
  },
});

const ToolsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginTop: '6px',
});

const ToolBadge = styled('div')({
  padding: '4px 10px',
  borderRadius: '12px',
  fontSize: '12px',
  fontWeight: 500,
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  border: '1px solid rgba(0,0,0,0.05)',
});

export interface MessageProps {
  role: 'user' | 'assistant' | 'code';
  text: string;
  activeTools?: string[];
  content?: Array<{
    type: string;
    text: {
      value: string;
      annotations: Array<{
        end_index: number;
        file_citation: { file_id: string };
        start_index: number;
        text: string;
        type: string;
      }>;
    };
  }>;
}

const UserMessage = ({ text }: { text: string }) => {
  if (text.includes('__initial_message__')) {
    return null;
  }
  return (
    <UserMessageStyled>
      <IconWrapper className='icon-wrapper'>
        <Person sx={{ fontSize: 16 }} />
      </IconWrapper>
      {text}
    </UserMessageStyled>
  );
};

const AssistantMessage = ({ text }: { text: string }) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.log('Link clicked:', e.currentTarget.href);
    const fileId = e.currentTarget.href.split('=')[1];
    console.log('File ID:', fileId);
    if (fileId) {
      (window as any).handleFileLink(fileId);
    }
  };

  return (
    <AssistantMessageStyled>
      <IconWrapper className='icon-wrapper'>
        <RobotHappy sx={{ fontSize: 16 }} />
      </IconWrapper>
      <Markdown
        components={{
          a: ({ node, ...props }) => (
            <a
              {...props}
              onClick={handleLinkClick}
              style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'none' }}
            />
          ),
        }}
      >
        {text}
      </Markdown>
    </AssistantMessageStyled>
  );
};

const CodeMessage = ({ text }: { text: string }) => {
  return (
    <CodeMessageStyled>
      {text.split('\n').map((line, index) => (
        <div key={index}>
          <span>{`${index + 1}. `}</span>
          {line}
        </div>
      ))}
    </CodeMessageStyled>
  );
};

const getToolName = (tool: string) => {
  const toolNames: { [key: string]: string } = {
    get_farm_location: 'Farm Location',
    get_weather_forecast: 'Weather Forecast',
    get_soil_moisture_data: 'Soil Moisture',
    get_crop_type: 'Crop Type',
  };
  return toolNames[tool] || tool;
};

const getToolColor = (tool: string) => {
  const toolColors: { [key: string]: string } = {
    get_farm_location: '#B6D0E2',
    get_weather_forecast: '#C1DFED',
    get_soil_moisture_data: '#C7E4E7',
    get_crop_type: '#D5EBD9',
  };
  return toolColors[tool] || '#D2E0ED';
};

const ThinkingIndicator = ({ activeTools }: { activeTools?: string[] }) => {
  const intl = useIntl();
  const thinkingMessage = intl.formatMessage({
    id: 'chat_thinking',
    defaultMessage: 'Thinking...',
  });

  return (
    <ThinkingMessage>
      {/* Add robot icon to match the assistant message */}
      <IconWrapper className='icon-wrapper'>
        <RobotHappy sx={{ fontSize: 16 }} />
      </IconWrapper>
      <ThinkingText>{thinkingMessage.replace('...', '')}</ThinkingText>

      {activeTools && activeTools.length > 0 && (
        <ToolsContainer>
          {activeTools.map((tool, index) => {
            const backgroundColor = getToolColor(tool);
            return (
              <ToolBadge
                key={index}
                style={{
                  backgroundColor,
                  color:
                    backgroundColor.startsWith('#') &&
                    (backgroundColor
                      .slice(1)
                      .match(/../g)
                      ?.reduce((sum, hex) => sum + parseInt(hex, 16), 0) || 0) /
                      3 >
                      170
                      ? '#333'
                      : '#fff',
                }}
              >
                {getToolName(tool)}
              </ToolBadge>
            );
          })}
        </ToolsContainer>
      )}
    </ThinkingMessage>
  );
};

export const Message: React.FC<MessageProps> = ({ role, text, activeTools }) => {
  return (
    <div
      className={`message ${role}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: role === 'user' ? 'flex-end' : 'flex-start',
        width: '100%',
      }}
    >
      {role === 'user' && <UserMessage text={text} />}
      {role === 'assistant' &&
        (text === 'Thinking...' ? (
          <ThinkingIndicator activeTools={activeTools} />
        ) : (
          <AssistantMessage text={text} />
        ))}
      {role === 'code' && <CodeMessage text={text} />}

      {/* Show active tools for non-thinking messages if needed */}
      {/* {activeTools && activeTools.length > 0 && text !== 'Thinking...' && (
        <ToolsContainer
          style={{
            marginTop: '8px',
            alignSelf: role === 'user' ? 'flex-end' : 'flex-start',
          }}
        >
          {activeTools.map((tool, index) => (
            <ToolBadge
              key={index}
              style={{
                backgroundColor: getToolColor(tool),
                color: `hsl(${(index * 40) % 360}, 70%, 30%)`,
              }}
            >
              {getToolName(tool)}
            </ToolBadge>
          ))}
        </ToolsContainer>
      )} */}
    </div>
  );
};

export const Messages = React.forwardRef<HTMLDivElement, MessagesProps>(
  ({ children, onIsAtBottomChange, style }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [isScrollable, setIsScrollable] = useState(false);

    const scrollToBottom = useCallback(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      const checkScrollable = () => {
        if (container) {
          const isContentScrollable = container.scrollHeight > container.clientHeight;
          setIsScrollable(isContentScrollable);

          const { scrollHeight, scrollTop, clientHeight } = container;
          const bottomThreshold = 100;
          const isAtBottom = scrollHeight - (scrollTop + clientHeight) <= bottomThreshold;
          setIsAtBottom(isAtBottom);
          onIsAtBottomChange?.(isAtBottom);
        }
      };

      // Check initially and on resize
      checkScrollable();
      const resizeObserver = new ResizeObserver(checkScrollable);
      if (container) {
        resizeObserver.observe(container);
      }

      // Check on scroll
      container?.addEventListener('scroll', checkScrollable);

      return () => {
        container?.removeEventListener('scroll', checkScrollable);
        resizeObserver.disconnect();
      };
    }, [onIsAtBottomChange]);

    return (
      <MessagesContainer
        ref={(node) => {
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
          if (containerRef) {
            (containerRef as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
        }}
        style={style} // Apply the style prop
      >
        {children}
        {isScrollable && !isAtBottom && (
          <ScrollButton onClick={scrollToBottom} aria-label='Scroll to bottom'>
            <ArrowDownward />
          </ScrollButton>
        )}
      </MessagesContainer>
    );
  }
);
