import type { DataLoggerConfiguration, MoistureSensorCalibration } from '@soilsense/shared';
import type { SiteDetails } from 'dataHandlers/ObservationSiteStore';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useIntl, type IntlShape } from 'react-intl';
import { useFarmStore } from '../../dataHandlers/RootStore';
import {
  VOLUMETRIC_WATER_CONTENT_BOT,
  VOLUMETRIC_WATER_CONTENT_MID,
  VOLUMETRIC_WATER_CONTENT_MID_BOT,
  VOLUMETRIC_WATER_CONTENT_TOP,
} from '../../dataHandlers/utils/constsAndTypes';
import getErrorMessage from '../../utils/getErrorMessage';
import useTraceUpdate from '../../utils/useTraceUpdate';
import CustomDialog from '../Dialog';
import { showSuccessSnackBar } from '../SnackBar';
import type { RowConfig, TableState } from './EditableTable';
import EditableTable, { getPwp } from './EditableTable';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

function convertDataLoggerConfigurationToTableState(
  configuration: DataLoggerConfiguration,
  tableState: TableState,
  namesList: readonly RowConfig[]
): TableState {
  return {
    columns: tableState.columns,
    data: namesList.map((c, index) => {
      return {
        rowIndex: index,
        sensor: c.label,
        fc: configuration[c.slot]?.calibration?.fieldCapacity,
        pwp: configuration[c.slot]?.calibration?.wiltingPoint,
      };
    }),
  };
}

function determineCalibration(
  fieldCapacity: number | undefined,
  wiltingPoint: number | undefined
): MoistureSensorCalibration | undefined {
  if (fieldCapacity == undefined || wiltingPoint == undefined) {
    return undefined;
  }
  if (isNaN(fieldCapacity) || isNaN(wiltingPoint)) {
    return undefined;
  }
  return { fieldCapacity, wiltingPoint };
}

export async function deriveCalibration(
  fieldCapacity: number | undefined,
  wiltingPoint: number | undefined,
  getIdToken: () => Promise<string>,
  intl: IntlShape
): Promise<MoistureSensorCalibration | undefined> {
  if (fieldCapacity == undefined || isNaN(fieldCapacity)) {
    return undefined;
  }
  if (wiltingPoint == undefined || isNaN(wiltingPoint)) {
    wiltingPoint = await getPwp(fieldCapacity, await getIdToken(), intl);
    if (wiltingPoint == undefined || isNaN(wiltingPoint)) {
      return undefined;
    }
  }
  return { fieldCapacity, wiltingPoint };
}

type Props = Readonly<{
  siteDetails: SiteDetails;
  close: () => void;
  country: string | undefined;
}>;

const INITIAL_TABLE_STATE: TableState = {
  columns: [
    { title: 'Sensor', field: 'sensor', editable: 'never' },
    {
      title: 'Field Capacity',
      field: 'fc',
      type: 'numeric',
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Wilting Point',
      field: 'pwp',
      type: 'numeric',
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ],
  data: [],
};

const FcCalibrationDialog: React.FC<Props> = observer((props) => {
  const { siteDetails, close, children, country } = props;
  const intl = useIntl();
  useTraceUpdate(props, `FcCalibrationDialog ${siteDetails.deviceIds.id}`);

  const {
    site: { id: siteId },
    savedConfiguration,
    configuration,
  } = siteDetails;
  const farmStore = useFarmStore();
  const sensorData = siteDetails.transformed.status == 'ready' ? siteDetails.transformed.value : undefined;

  const namesDefinitions = useMemo(
    () =>
      ([] as RowConfig[]).concat(
        [
          {
            slot: 'cableTop',
            var: VOLUMETRIC_WATER_CONTENT_TOP,
            label: `${configuration.cableTop.depth} cm`,
          },
        ],
        configuration.cableMiddle != undefined
          ? [
              {
                slot: 'cableMiddle',
                var: VOLUMETRIC_WATER_CONTENT_MID,
                label: `${configuration.cableMiddle?.depth} cm`,
              },
            ]
          : [],
        configuration.cableMiddleBottom != undefined
          ? [
              {
                slot: 'cableMiddleBottom',
                var: VOLUMETRIC_WATER_CONTENT_MID_BOT,
                label: `${configuration.cableMiddleBottom?.depth} cm`,
              },
            ]
          : [],
        [
          {
            slot: 'cableBottom',
            var: VOLUMETRIC_WATER_CONTENT_BOT,
            label: `${configuration.cableBottom.depth} cm`,
          },
        ]
      ),
    [
      configuration.cableBottom.depth,
      configuration.cableMiddle,
      configuration.cableMiddleBottom,
      configuration.cableTop.depth,
    ]
  );

  const [tableState, setTableState] = React.useState(
    convertDataLoggerConfigurationToTableState(configuration, INITIAL_TABLE_STATE, namesDefinitions)
  );

  const nothingChanged = useMemo(() => {
    return tableState.data.every(({ fc, pwp }, index) => {
      const names = namesDefinitions[index];
      return (
        fc === savedConfiguration?.[names.slot]?.calibration?.fieldCapacity &&
        pwp === savedConfiguration?.[names.slot]?.calibration?.wiltingPoint
      );
    });
  }, [namesDefinitions, savedConfiguration, tableState.data]);

  useEffect(() => {
    const update: Writeable<Partial<DataLoggerConfiguration>> = {};
    tableState.data.forEach(({ fc, pwp }, index) => {
      const names = namesDefinitions[index];
      const saved = savedConfiguration[names.slot];
      if (saved != undefined) {
        update[names.slot] = {
          ...saved,
          calibration: determineCalibration(fc, pwp),
        };
      }
    });
    farmStore.setObservationSiteUpdate(siteId, update);
  }, [siteId, namesDefinitions, savedConfiguration, tableState.data, farmStore]);

  const onRevert = React.useCallback(async () => {
    farmStore.cancelObservationSiteUpdate(siteId);
    setTableState(convertDataLoggerConfigurationToTableState(savedConfiguration, tableState, namesDefinitions));
  }, [farmStore, siteId, savedConfiguration, tableState, namesDefinitions]);

  const handleClose = React.useCallback(async () => {
    farmStore.cancelObservationSiteUpdate(siteId);
    setTableState(convertDataLoggerConfigurationToTableState(savedConfiguration, tableState, namesDefinitions));
    close();
  }, [farmStore, siteId, savedConfiguration, tableState, namesDefinitions, close]);

  const handleSave = React.useCallback(async () => {
    try {
      await farmStore.applyObservationSiteUpdate(siteId);
      // showSuccessSnackBar('Succesfully updated sensor information');
      showSuccessSnackBar(intl.formatMessage({ id: 'successfully_updated_sensor_information' }));
      close();
    } catch (e) {
      console.error(e);
      showSuccessSnackBar(
        intl.formatMessage(
          { id: 'failed_updating_sensor_information' },
          {
            error: getErrorMessage(e),
          }
        )
      );
    }
  }, [close, farmStore, siteId, intl]);

  if (sensorData?.data == undefined) {
    return null;
  }

  return (
    <CustomDialog
      title={intl.formatMessage({ id: 'adjust_field_capacity' })}
      handleClose={handleClose}
      open={true}
      saveLabel={intl.formatMessage({ id: 'apply' })}
      handleSave={handleSave}
      saveDisabled={nothingChanged}
      fullWidth={false}
    >
      <div
        style={{
          padding: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        {country ? (
          <EditableTable
            state={tableState}
            setState={setTableState}
            sensorData={sensorData.data}
            sensorCountry={country}
            onRevert={onRevert}
            nothingChanged={nothingChanged}
            namesDefinitions={namesDefinitions}
          />
        ) : (
          intl.formatMessage({ id: 'farm_country_not_specified' })
        )}
      </div>
      {children}
    </CustomDialog>
  );
});

export default FcCalibrationDialog;
